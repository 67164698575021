import { FC, useEffect, useRef, useState } from "react";
import { SelectedCell, setCurrentlySelectedCell } from "../../slices";
import ReactDOM from "react-dom";
import { createDefaultRows, createFooterRow, createHeaderRow } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectCurrentMonth, selectCurrentYear } from "../../selectors/uiSelectors";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";
import { DataRowRenderer, FooterRowRenderer, HeaderRowRenderer } from "./RowRenderers";

type Props = {
  selectedCell?: SelectedCell;
};

export const PortalSubGrid: FC<Props> = ({ selectedCell }) => {
  const {
    coords,
    rowCell,
    originalCell,
    originalBudget,
    shouldRenderInSubGrid,
    subGridViewResolution,
  } = selectedCell ?? {};
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setCurrentlySelectedCell(undefined));
  };
  const currentMonth = useAppSelector(selectCurrentMonth);
  const currentYear = useAppSelector(selectCurrentYear);

  const callbackRef = (node: HTMLDivElement | null) => {
    if (shouldRenderInSubGrid && node) {
      const systemDate = new Date();
      const systemYear = systemDate.getFullYear();
      const systemMonth = systemDate.getMonth() + 1;
      const systemDay = systemDate.getDate();

      let monthIndex: number = systemMonth;
      let dayIndex: number = systemDay;

      if (systemMonth !== currentMonth) {
        dayIndex = 1;
        monthIndex = currentMonth;
      }

      if (!node) return;
      const el = node.querySelector<HTMLDivElement>(
        `#sub-grid-header-cell-${systemYear}-${monthIndex}-${dayIndex}`
      );
      console.log(
        "scrolling subgrid to",
        `sub-grid-header-cell-${systemYear}-${monthIndex}-${dayIndex}`,
        el
      );
      if (!el) return;
      el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const headerRow =
    originalBudget &&
    rowCell &&
    subGridViewResolution &&
    createHeaderRow(originalBudget, subGridViewResolution, rowCell.monthIndex, rowCell.year);

  const rows =
    originalBudget && originalCell && subGridViewResolution && rowCell
      ? createDefaultRows(
          { ...originalBudget, items: [originalCell] },
          subGridViewResolution,
          rowCell.year,
          rowCell.monthIndex
        )
      : [];

  const footerRow = originalBudget && rows && createFooterRow(originalBudget, rows);
  return (
    <Dialog
      open={!!shouldRenderInSubGrid}
      as="div"
      className="relative z-10 focus:outline-none"
      onClose={handleClose}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4">
          <DialogPanel
            transition
            as="div"
            ref={callbackRef}
            className=" w-10/12  transition-all bg-white/5  shadow-2xl border-gray-300 dark:border-slate-600 border backdrop-blur-xl rounded-xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <div className="flex flex-row items-center justify-between p-3 dark:text-slate-200 ">
              <div className="font-bold">
                <p>{originalBudget?.name}</p>
                <p className="text-sm font-normal text-gray-400">{originalBudget?.description}</p>
              </div>
            </div>
            <div className="overflow-auto bg-white border border-gray-300 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200 ">
              <div className="grid auto-rows-max">
                {headerRow && (
                  <HeaderRowRenderer
                    isSubGrid={true}
                    viewResolution={subGridViewResolution}
                    row={headerRow}
                  />
                )}
                {originalBudget && (
                  <DataRowRenderer rows={rows} budget={originalBudget} isSubGrid={true} />
                )}
                {/* {footerRow && originalBudget && (
                  <FooterRowRenderer row={footerRow} budget={originalBudget} />
                )} */}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
