import { FC, useState } from "react";
import { Budget } from "../../../slices";
import { TextAreaInput, TextInput } from "../../Inputs";
import { Button } from "../../Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { deleteBudget, updateBudget } from "../../../api";
import { selectUserUid } from "../../../selectors";
import { Save, Trash2 } from "react-feather";
import { useLocation } from "wouter";

type Props = {
  budget: Budget;
};

export const BudgetSettingsPanel: FC<Props> = ({ budget }) => {
  const [_, setLocation] = useLocation();
  const [localBudget, setLocalBudget] = useState(budget);
  const dispatch = useAppDispatch();
  const uid = useAppSelector(selectUserUid);
  if (!uid) return null;
  const updateLocalBudget = (field: string, value: string | number) => {
    setLocalBudget({ ...localBudget, [field]: value });
  };
  const handleOnSubmit = async () => {
    // DELETE LOCAL ITEMS HERE BEFORE UPDATING
    const { items, ...rest } = localBudget;
    await dispatch(updateBudget({ uid, budget: rest }));
  };
  const handleDeleteBudget = async () => {
    if ((budget?.linkedFromBudgetIds ?? []).length > 0) {
      alert("This budget is linked to other budgets. Please unlink them before deleting.");
      return;
    }
    await dispatch(deleteBudget({ uid, budgetId: budget.id }));
    setLocation("/budgets");
  };
  const isValid =
    ((localBudget?.name ?? "").length > 0 && localBudget?.name !== budget.name) ||
    ((localBudget?.currencyShortCode ?? "").length > 0 &&
      localBudget?.currencyShortCode !== budget.currencyShortCode) ||
    localBudget?.description !== budget.description;

  return (
    <div className="w-full h-full pb-3 mb-4 ">
      <p className="my-6 font-bold">Budget Settings</p>
      <div className="flex flex-col justify-between">
        <pre>{JSON.stringify(localBudget, null, 2)}</pre>
        <TextInput
          label="Name"
          value={localBudget.name}
          onChange={(e) => updateLocalBudget("name", e.target.value)}
        />
        <TextAreaInput
          label="Description"
          value={localBudget.description || ""}
          onChange={(e) => updateLocalBudget("description", e.target.value)}
        />
        <TextInput
          label="Currency Prefix"
          value={localBudget.currencyShortCode || "USD"}
          onChange={(e) => updateLocalBudget("currencyShortCode", e.target.value)}
        />
        <Button
          disabled={!isValid}
          type="primary"
          label="Save"
          icon={<Save />}
          onClick={handleOnSubmit}
          additionalClassnames="w-full mt-4 font-bold"
        />
        <Button
          additionalClassnames="mt-4"
          type="warning"
          icon={<Trash2 />}
          label="Delete Budget"
          onClick={handleDeleteBudget}
        />
      </div>
    </div>
  );
};
