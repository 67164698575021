import { FC } from "react";
import classnames from "classnames";
import { BudgetItem } from "../../../slices";
import { CELL_CLASSNAMES } from "./cellClassnames";

export const RowHeaderCellRenderer: FC<{ cell: BudgetItem; sticky?: "left" | "right" }> = ({
  cell,
  sticky,
}) => {
  const wrapperClassnames = classnames(CELL_CLASSNAMES.base, CELL_CLASSNAMES.header, {
    [CELL_CLASSNAMES.stickyLeft]: sticky === "left",
    [CELL_CLASSNAMES.stickyRight]: sticky === "right",
  });

  return <div className={wrapperClassnames}>{cell.label || cell.value}</div>;
};
