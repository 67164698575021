import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { selectViewResolution } from "./uiSelectors";

const root = (state: RootState) => state.budgets;
export const selectBudgetsArray = createSelector(root, (budgets) => Object.values(budgets));
export const selectBudgetById = (id?: string) =>
  createSelector(root, (budgets) => (id ? budgets[id] : undefined));
export const selectNewestThreeBudgets = createSelector(selectBudgetsArray, (budgets) =>
  budgets.slice(0, 3)
);

export const selectLinkedBudgets = (budgetId?: string) =>
  createSelector([selectBudgetById(budgetId), root], (budget, budgets) => {
    if (!budget) return [];
    return budget.linkedBudgetIds?.map((id) => budgets[id]);
  });

// export const selectBudgetMatrix = (budgetId?: string) =>
//   createSelector(
//     [selectBudgetById(budgetId), selectLinkedBudgets(budgetId), selectViewResolution],
//     (budget, linkedBudgets, viewResolution) => {
//       return createMatrix(viewResolution, budget, linkedBudgets);
//     }
//   );
