import { RouteProps } from "wouter";

import { lazy } from "react";

const AuthView = lazy(async () => await import("../views/AuthView"));
const ProfileView = lazy(async () => await import("../views/ProfileView"));
const BudgetListView = lazy(async () => await import("../views/BudgetListView"));
const BudgetView = lazy(async () => await import("../views/BudgetView"));

export const routes: RouteProps[] = [
  { path: "/auth", component: AuthView },
  { path: "/profile", component: ProfileView },
  { path: "/budgets", component: BudgetListView },
  { path: "/budgets/:id", component: BudgetView },
];
