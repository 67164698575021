import { FC, useState } from "react";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { BudgetItem } from "../../slices";
import { selectUserUid } from "../../selectors";
import { addBudgetItem } from "../../api";
import {
  Button,
  NumberInput,
  RadioInput,
  SelectInput,
  TextInput,
  TypeAhead,
  TypeAheadSuggestion,
} from "../../components";
import { DateInput } from "../Inputs";
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  Interval,
  intervalToDuration,
  set,
} from "date-fns";
import { Repeat } from "react-feather";

const BUDGET_ITEM_SUGGESTIONS: TypeAheadSuggestion[] = require("../../consts/typeahead-budgetItems.json");

export const BudgetInputForm = ({
  budgetId,
  currentYear,
  currentMonth,
}: {
  budgetId: string;
  currentYear: number;
  currentMonth?: number;
}) => {
  const dispatch = useAppDispatch();
  const uid = useAppSelector(selectUserUid);

  const [budgetItem, setBudgetItem] = useState<Partial<BudgetItem>>({
    value: 0,
    monthIndex: currentMonth ?? 1,
    dayIndex: 1,
    year: currentYear,
    repeats: "none",
  });

  const [endDate, setEndDate] = useState<string | undefined>();
  const [endValue, setEndValue] = useState<number | undefined>();

  if (!uid) return null;

  const isValid = (budgetItem?.label ?? "").length > 0 && (budgetItem?.value ?? 0) !== 0;

  const handleChangeField = (field: string, value: string | number) => {
    setBudgetItem({ ...budgetItem, [field]: value });
  };

  const handleOnSubmit = async () => {
    const correctedValue =
      budgetItem.type === "expense" && (budgetItem.value ?? 0) > 0
        ? -(budgetItem?.value ?? 0)
        : budgetItem.value;

    await dispatch(
      addBudgetItem({ uid, budgetId, item: { ...budgetItem, value: correctedValue } as BudgetItem })
    );

    setBudgetItem({
      value: 0,
      monthIndex: 1,
      dayIndex: 1,
      type: "income",
      year: currentYear,
    });
  };

  const handleStartDateChange = (newDateString?: string) => {
    if (!newDateString) {
      setBudgetItem({
        ...budgetItem,
        year: undefined,
        monthIndex: undefined,
        dayIndex: undefined,
      });
      return;
    }
    const split = newDateString.split("-");
    const year = Number(split[0]);
    const month = Number(split[1]);
    const day = Number(split[2]);
    setBudgetItem({ ...budgetItem, year, monthIndex: month, dayIndex: day });
  };
  const handleEndValueChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target.value) {
      setEndValue(undefined);
      setEndDate(undefined);
      return;
    }

    const newEndValue = e?.target.value ? Number(e.target.value) : 0;
    setEndValue(newEndValue);

    if (newEndValue === 0) {
      setEndDate(undefined);
      setBudgetItem({
        ...budgetItem,
        endYear: undefined,
        endMonthIndex: undefined,
        endDayIndex: undefined,
      });
      return;
    }
    if (budgetItem.value && budgetItem.year && budgetItem.monthIndex && budgetItem.dayIndex) {
      const startDate = new Date(budgetItem.year, budgetItem.monthIndex - 1, budgetItem.dayIndex);
      const multiplier = Math.round(newEndValue / budgetItem.value);

      let newEndDate: Date;

      if (budgetItem.repeats === "daily") {
        newEndDate = addDays(startDate, multiplier);
      } else {
        newEndDate = addMonths(startDate, multiplier);
      }
      if (newEndDate) {
        const endDateString = newEndDate.toISOString().split("T")[0];
        const newEndYear = newEndDate.getFullYear();
        const newEndMonth = newEndDate.getMonth() + 1;
        const newEndDay = newEndDate.getDate();

        setEndDate(endDateString);
        setBudgetItem({
          ...budgetItem,
          endYear: newEndYear,
          endMonthIndex: newEndMonth,
          endDayIndex: newEndDay,
        });
      }
    }
  };
  const handleEndDateChange = (newDateString?: string) => {
    if (!newDateString) {
      setEndDate(undefined);
      setEndValue(undefined);
      setBudgetItem({
        ...budgetItem,
        endYear: undefined,
        endMonthIndex: undefined,
        endDayIndex: undefined,
      });
      return;
    }
    const split = newDateString.split("-");
    const year = Number(split[0]);
    const month = Number(split[1]);
    const day = Number(split[2]);

    setEndDate(newDateString);
    setBudgetItem({
      ...budgetItem,
      endYear: year,
      endMonthIndex: month,
      endDayIndex: day,
    });
    if (budgetItem.value && budgetItem.year && budgetItem.monthIndex && budgetItem.dayIndex) {
      const startDate = new Date(budgetItem.year, budgetItem.monthIndex - 1, budgetItem.dayIndex);
      const endDate = new Date(year, month - 1, day);
      const range: Interval = { start: startDate, end: endDate };

      const multiplier =
        budgetItem.repeats === "daily"
          ? eachDayOfInterval(range).length
          : eachMonthOfInterval(range).length;

      const newEndValue = budgetItem.value * multiplier;

      setEndValue(newEndValue);
    }
  };

  const wrapperClassnames = classnames("p-6 border-8 transition-colors rounded-2xl relative", {
    "border-expense": budgetItem.type === "expense",
    "border-income": budgetItem.type === "income",
  });

  return (
    <div className={wrapperClassnames}>
      <p className="mb-3 text-lg font-bold">Add new {budgetItem.type}</p>
      <TypeAhead
        textInputProps={{ label: "Name" }}
        onChange={(suggestion) => {
          setBudgetItem({ ...budgetItem, label: suggestion.label });
        }}
        onTextChange={(value) => handleChangeField("label", value)}
        value={budgetItem.label ?? ""}
        suggestions={BUDGET_ITEM_SUGGESTIONS}
      />
      {/* <TextInput
        label="Name"
        value={budgetItem.label ?? ""}
        onChange={(e) => handleChangeField("label", e.target.value)}
      /> */}

      {/* <RadioInput
        label="Type"
        value={budgetItem.type ?? ""}
        onChange={(value) => handleChangeField("type", value)}
        values={[
          { value: "income", label: "Income" },
          { value: "expense", label: "Expense" },
        ]}
      /> */}

      <NumberInput
        label="Value"
        value={budgetItem.value ?? 0}
        onChange={(e) => {
          const newValue = e?.target.value ? Number(e?.target.value) : 0;
          setBudgetItem({
            ...budgetItem,
            value: newValue,
            type: newValue > 0 ? "income" : newValue < 0 ? "expense" : undefined,
          });
          // handleChangeField("value", Number(e.target.value));
        }}
        // prefix={(budgetItem.value ?? 0) >= 0 ? "+" : "-"}
      />
      <DateInput
        label={budgetItem.repeats !== "none" ? "Starts On" : "Occurs On"}
        year={budgetItem.year}
        month={budgetItem.monthIndex}
        day={budgetItem.dayIndex}
        type="date"
        onChange={handleStartDateChange}
      />

      <SelectInput
        label="Repeats"
        value={budgetItem.repeats ?? "none"}
        onChange={(newValue: string) => {
          if (newValue === "none") {
            setEndDate(undefined);
            setEndValue(undefined);
            setBudgetItem({
              ...budgetItem,
              repeats: "none",
              endYear: undefined,
              endMonthIndex: undefined,
              endDayIndex: undefined,
            });
          } else {
            handleChangeField("repeats", newValue);
          }
        }}
        values={[
          { value: "none", label: "None" },
          { value: "daily", label: "Daily" },
          { value: "monthly", label: "Monthly" },
          // { value: "weekly", label: "Weekly" },
          // { value: "yearly", label: "Yearly" },
        ]}
      />

      {budgetItem.repeats !== "none" && (
        <div className="flex flex-row items-end justify-between">
          <DateInput
            type={budgetItem.repeats === "daily" ? "date" : "month"}
            label="End Date"
            year={budgetItem.endYear}
            month={budgetItem.endMonthIndex}
            day={budgetItem.endDayIndex}
            onChange={handleEndDateChange}
            additionalClasses="!mb-0 flex-1"
          />
          <Repeat className="w-8 h-8 mx-4 mb-1" />

          <NumberInput
            label="End Value"
            value={endValue ?? 0}
            additionalClasses="!mb-0 flex-1"
            onChange={handleEndValueChange}
          />
        </div>
      )}

      <Button
        disabled={!isValid}
        type="primary"
        label="Add Item"
        onClick={handleOnSubmit}
        additionalClassnames="w-full font-bold mt-6"
      />
      <pre>{JSON.stringify(budgetItem, null, 2)}</pre>
    </div>
  );
};
