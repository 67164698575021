import { ColorScale } from "@nivo/calendar";
import colors from "tailwindcss/colors";
// console.log(colors);

// const negatives = Object.values(colors.rose).reverse();
// const positives = Object.values(colors.teal);

// export const rangeColors = [
//   negatives[4],
//   negatives[6],
//   negatives[8],
//   negatives[10],
//   "transparent",
//   positives[4],
//   positives[6],
//   positives[8],
//   positives[10],
// ];

// export const colorScaleFn = (values: number[]) => {
//   const min = Math.min(...values);
//   const max = Math.max(...values);

//   return (value: number) => {
//     const normalizedValue = (value - min) / (max - min);
//     const index = Math.floor(normalizedValue * (rangeColors.length - 1));
//     console.log(value, normalizedValue, index, min, max);
//     return rangeColors[index];
//   };
// };
// colorScaleFn.ticks = () => rangeColors;
// //@ts-ignore
// export const calendarColorScaleDark: ColorScale = colorScaleFn;

// // const colorScaleFn = (value: number) => {
// //   // normalize value to be between 0 and _colors.length - 1
// //   const normalizedValue = Math.max(0, Math.min(1, value));
// //   console.log(value, normalizedValue);
// //   const index = Math.floor(normalizedValue * (_colors.length - 1));

// //   return _colors[index];
// // };

export const darkTheme = {
  // background: "#ffffff",
  text: {
    fontSize: 16,
    fill: colors.slate[300],
    outlineWidth: 0,
    outlineColor: "transparent",
  },
  axis: {
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    ticks: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  grid: {
    line: {
      stroke: "#dddddd",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 16,
        fill: colors.slate[300],
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    text: {
      fontSize: 16,
      fill: colors.slate[300],
      outlineWidth: 0,
      outlineColor: "transparent",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    wrapper: {},
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

export const lightTheme = {
  // background: "#ffffff",
  text: {
    fontSize: 16,
    fill: colors.gray[500],
    outlineWidth: 0,
    outlineColor: "transparent",
  },
  axis: {
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    ticks: {
      line: {
        stroke: "#777777",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  grid: {
    line: {
      stroke: "#dddddd",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 16,
        fill: colors.gray[500],
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
    text: {
      fontSize: 16,
      fill: colors.gray[500],
      outlineWidth: 0,
      outlineColor: "transparent",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#333333",
        outlineWidth: 0,
        outlineColor: "transparent",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#333333",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    wrapper: {},
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
};

const calendarColorPropsDark = {
  emptyColor: "transparent",
  monthBorderColor: colors.slate[500],
  dayBorderColor: colors.slate[500],
  theme: darkTheme,
};

const calendarColorPropsLight = {
  emptyColor: "transparent",
  monthBorderColor: colors.gray[300],
  dayBorderColor: colors.gray[300],
  theme: lightTheme,
};

export const calendarColorProps = () => {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return calendarColorPropsDark;
  }
  return calendarColorPropsLight;
};
