import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { set } from "date-fns";
import { Budget, BudgetItem } from ".";

export type ViewResolution = "month" | "year";
export type ViewType = "table" | "calendar";

export type SelectedCell = {
  coords: number[];
  rowCell: BudgetItem;
  originalCell: BudgetItem;
  originalBudget: Budget;
  shouldRenderInSubGrid: boolean;
  subGridViewResolution: ViewResolution;
};
interface UIState {
  viewResolution: ViewResolution;
  currentYear: number;
  currentMonth: number;
  collapsedRowIds?: string[];
  currentlySelectedCell?: SelectedCell;
  systemDate?: Date;
  currentViewType?: ViewType;
}

const initialState: UIState = {
  viewResolution: "year",
  currentViewType: "table",
  currentYear: new Date().getFullYear(),
  currentMonth: new Date().getMonth() + 1,
};

export const counterSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setCurrentViewType: (state, action: PayloadAction<ViewType>) => {
      state.currentViewType = action.payload;
    },
    setCollapsedRowIds: (state, action: PayloadAction<string>) => {
      if (state.collapsedRowIds?.includes(action.payload)) {
        state.collapsedRowIds = state.collapsedRowIds?.filter((i) => i !== action.payload);
      } else {
        state.collapsedRowIds = [...(state.collapsedRowIds ?? []), action.payload];
      }
    },

    setViewResolution: (state, action: PayloadAction<"month" | "year">) => {
      state.viewResolution = action.payload;
      state.currentlySelectedCell = undefined;
    },
    setCurrentYear: (state, action: PayloadAction<number>) => {
      state.currentYear = action.payload;
    },
    setCurrentMonth: (state, action: PayloadAction<number>) => {
      state.currentMonth = action.payload;
    },
    setCurrentlySelectedCell: (state, action: PayloadAction<SelectedCell | undefined>) => {
      state.currentlySelectedCell = action.payload;
    },
  },
});

export const {
  setCurrentViewType,
  setCurrentlySelectedCell,
  setCollapsedRowIds,
  setViewResolution,
  setCurrentMonth,
  setCurrentYear,
} = counterSlice.actions;

export default counterSlice.reducer;
