import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type User = {
  loggedIn: boolean;
  data?: {
    uid: string;
    email: string;
    photoURL: string | null;
    providerId: string;
    displayName: string | null;
    budgets: string[];
  };
};

const initialState: User = { loggedIn: false };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      return action.payload;
    },
    unsetUser: (state) => {
      return;
    },
  },
});

export const { setUser, unsetUser } = userSlice.actions;

export default userSlice.reducer;
