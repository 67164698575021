import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  getFirestore,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { app } from "../App";
import { doc, setDoc, getDocs } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Budget, BudgetItem, BudgetItemOneOff } from "../slices";

export const createNewBudget = createAsyncThunk(
  "budgets/createNewBudget",
  async ({ uid, name }: { uid: string; name: string }) => {
    const db = await getFirestore(app);
    const ref = doc(collection(db, "users", uid, "budgets"));
    const createdOn = Timestamp.now();
    const data = {
      id: ref.id,
      name,
      createdOn,
      year: new Date().getFullYear(),
      items: [],
    };
    await setDoc(ref, data);
    return data;
  }
);

export const updateBudget = createAsyncThunk(
  "budgets/updateBudget",
  async ({ uid, budget }: { uid: string; budget: Budget }) => {
    const db = await getFirestore(app);
    const ref = doc(db, "users", uid, "budgets", budget.id);
    await updateDoc(ref, budget);
    return budget;
  }
);

export const linkBudgets = createAsyncThunk(
  "budgets/linkBudgets",
  async ({
    uid,
    budgetId,
    linkedBudgetId,
  }: {
    uid: string;
    budgetId: string;
    linkedBudgetId: string;
  }) => {
    const db = await getFirestore(app);
    const batch = writeBatch(db);

    const budgetRef = doc(db, "users", uid, "budgets", budgetId);
    const linkedBudgetRef = doc(db, "users", uid, "budgets", linkedBudgetId);

    batch.update(budgetRef, { linkedBudgetIds: arrayUnion(linkedBudgetId) });
    batch.update(linkedBudgetRef, { linkedFromBudgetIds: arrayUnion(budgetId) });

    await batch.commit();

    return { budgetId, linkedBudgetId };
  }
);

export const removeLinkedBudget = createAsyncThunk(
  "budgets/removeLinkedBudget",
  async ({
    uid,
    budgetId,
    linkedBudgetId,
  }: {
    uid: string;
    budgetId: string;
    linkedBudgetId: string;
  }) => {
    const db = await getFirestore(app);
    const batch = writeBatch(db);

    const budgetRef = doc(db, "users", uid, "budgets", budgetId);
    const linkedBudgetRef = doc(db, "users", uid, "budgets", linkedBudgetId);

    batch.update(budgetRef, { linkedBudgetIds: arrayRemove(linkedBudgetId) });
    batch.update(linkedBudgetRef, { linkedFromBudgetIds: arrayRemove(budgetId) });

    await batch.commit();

    return { budgetId, linkedBudgetId };
  }
);

export const getAllBudgets = createAsyncThunk(
  "budgets/getAllBudgets",
  async ({ uid }: { uid: string }) => {
    const db = await getFirestore(app);
    const ref = await collection(db, "users", uid, "budgets");
    const queryRef = await query(ref, orderBy("createdOn", "desc"));
    const docs = await getDocs(queryRef);

    const budgets = docs.docs.map((doc) => doc.data() as Budget);
    const budgetsWithItems = await Promise.all(
      budgets.map(async (budget) => {
        const itemsRef = await query(
          collection(db, "users", uid, "budgets", budget.id, "items"),
          orderBy("type", "desc")
        );
        const itemsDocs = await getDocs(itemsRef);
        const items = itemsDocs.docs.map((doc) => doc.data() as BudgetItem);

        const itemsWithOneOffs = await Promise.all(
          items.map(async (item) => {
            if (!item.id) return item;
            const oneOffsRef = collection(
              db,
              "users",
              uid,
              "budgets",
              budget.id,
              "items",
              item.id,
              "oneOffs"
            );
            const oneOffsDocs = await getDocs(oneOffsRef);
            const oneOffs = oneOffsDocs.docs.map((doc) => doc.data() as BudgetItemOneOff);
            return { ...item, oneOffs };
          })
        );

        return { ...budget, items: itemsWithOneOffs };
      })
    );
    return budgetsWithItems;
  }
);

export const deleteBudget = createAsyncThunk(
  "budgets/deleteBudget",
  async ({ uid, budgetId }: { uid: string; budgetId: string }) => {
    const db = await getFirestore(app);
    const ref = doc(db, "users", uid, "budgets", budgetId);
    await deleteDoc(ref);
    return budgetId;
  }
);
