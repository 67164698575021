import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const root = (state: RootState) => state.user;
export const selectUserBudget = createSelector(
  root,
  (state) => state.data?.budgets
);
export const selectUserUid = createSelector(root, (state) => state.data?.uid);
export const selectUser = createSelector(root, (state) => state.data);
