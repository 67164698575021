import { FC } from "react";
import classnames from "classnames";
import { Budget, BudgetRow } from "../../../slices";
import { ROW_CLASSNAMES } from "./rowClassnames";
import { CELL_CLASSNAMES } from "../CellRenderers";
import { ChevronDown, ChevronUp, Maximize2, Minimize2 } from "react-feather";
import { FooterRowRenderer } from "./FooterRowRenderer";
import { DataRowRenderer } from "./DataRowRenderer";

type Props = {
  budget: Budget;
  onClick?: () => void;
  canCollapse?: boolean;
  isCollapsed?: boolean;
  dataRows: BudgetRow[];
  footerRow: BudgetRow;
};

export const InfoRowRenderer: FC<Props> = ({
  budget,
  onClick,
  canCollapse,
  isCollapsed,
  dataRows,
  footerRow,
}) => {
  const wrapperClassnames = classnames(
    ROW_CLASSNAMES.base,
    ROW_CLASSNAMES.header,
    ROW_CLASSNAMES.info,
    "divide-x-0 transition-all",
    {
      [ROW_CLASSNAMES.infoCanCollapse]: canCollapse,
    }
  );
  const cellClassnames = classnames(CELL_CLASSNAMES.base, CELL_CLASSNAMES.info);
  const iconClassnames = classnames("transition-all", {
    "opacity-25": !canCollapse,
    "-rotate-90": isCollapsed,
  });
  const icon = isCollapsed ? <Maximize2 /> : <Minimize2 />;
  return (
    <>
      <div className={wrapperClassnames} onClick={onClick}>
        <div className={cellClassnames}>
          <ChevronDown className={iconClassnames} size={20} />
          <div className="flex flex-col justify-center">
            <p className="text-gray-400">{budget?.name}</p>
            {budget?.description && !isCollapsed && (
              <p className="text-xs text-gray-400">{budget?.description}</p>
            )}
          </div>
        </div>
        <div />
        <div />
      </div>
      {isCollapsed ? (
        <FooterRowRenderer budget={budget} row={footerRow} />
      ) : (
        <DataRowRenderer budget={budget} rows={dataRows} />
      )}
    </>
  );
};
