import { FC } from "react";
import classnames from "classnames";

type Props = {
  label?: string;
  values: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
  additionalClasses?: string;
};

export const SelectInput: FC<Props> = ({ label, value, values, onChange, additionalClasses }) => {
  const wrapperClassnames = classnames(
    "flex flex-col items-stretch mb-3 w-full",
    additionalClasses
  );
  return (
    <div className={wrapperClassnames}>
      {label && (
        <label htmlFor={label} className="mb-1 text-sm font-semibold">
          {label}
        </label>
      )}
      <select
        id="monthSelect"
        value={value}
        className="flex flex-1 w-full p-2 border rounded-xl dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
        onChange={(e) => onChange(e.target.value)}
      >
        {values.map((v) => (
          <option key={v.value} value={v.value}>
            {v.label}
          </option>
        ))}
      </select>
    </div>
  );
};
