import { CalendarData, CalendarDatum } from "@nivo/calendar";
import { getDaysInMonth } from "date-fns";
import { createDefaultRows } from "./matrix";
import { Budget, BudgetItem, BudgetRow } from "../slices";
import { create } from "domain";

export const makeCalendarChartDataForMonth = (
  budgets: Budget[],
  year: number,
  monthIndex: number
): CalendarDatum[] => {
  const date = new Date(year, monthIndex - 1, 1, 0, 0, 0);
  const daysInMonth = getDaysInMonth(date);

  const paddedMonthIndex = monthIndex.toString().padStart(2, "0");

  const dayObj: { [key: string]: CalendarDatum } = {};
  //    Array.from({ length: daysInMonth }, (_, i) => i + 1).reduce((acc, curr) => {
  //     const paddedDayIndex = curr.toString().padStart(2, "0");
  //     return {
  //       ...acc,
  //       [`${year}-${paddedMonthIndex}-${paddedDayIndex}`]: {
  //         day: `${year}-${paddedMonthIndex}-${paddedDayIndex}`,
  //         value: 0,
  //       },
  //     };
  //   }, {} as { [key: string]: CalendarDatum });
  //   console.log(dayObj);
  budgets.forEach((budget) => {
    const rows = createDefaultRows(budget, "month", year, monthIndex);

    rows.forEach((row) => {
      row.items.forEach((cell) => {
        if (
          cell.type === "rowFooter" ||
          cell.type === "rowHeader" ||
          cell.type === "hidden" ||
          cell.type === "empty"
        ) {
          return;
        }
        const paddedMonthIndex = monthIndex.toString().padStart(2, "0");
        const paddedDayIndex = (cell.dayIndex ?? 1).toString().padStart(2, "0");

        const dayString = `${year}-${paddedMonthIndex}-${paddedDayIndex}`;

        if (!dayObj[dayString]) {
          dayObj[dayString] = {
            day: dayString,
            value: 0,
          };
        }

        dayObj[dayString].value += cell?.value ?? 0;
      });
    });
    // console.log(dayObj);
  });

  //   const rows = budgets
  //     .reduce((acc, curr) => {
  //       return [...acc, ...createDefaultRows(curr, "month", year, monthIndex)];
  //     }, [] as BudgetRow[])
  //     .map((row) => {
  //       return row.items.filter((f) => {
  //         return (
  //           f.type !== "rowFooter" && f.type !== "rowHeader" && f.value !== 0 && f.value !== undefined
  //         );
  //       });
  //     })
  //     .flat()
  //     .map((cell) => ({
  //       value: cell.value ?? 0,
  //       year: cell.year,
  //       monthIndex: cell.monthIndex,
  //       dayIndex: cell.dayIndex,
  //     }));
  //   const rowData = createDefaultRows(budget, "month", year, monthIndex);

  //   rows.forEach((item) => {

  //     const day = `${item.year}-${paddedMonthIndex}-${paddedDayIndex}`;
  //     console.log(day, item.value, dayObj[day]);
  //     if (dayObj[day]) {
  //       dayObj[day].value += item.value ?? 0;
  //     }
  //   });

  return Object.values(dayObj);
};
