import { FC, useCallback, useEffect, useRef, useState } from "react";
import { TextInput, TextInputProps } from "../Inputs";
import { useDebounce } from "use-debounce";
import classnames from "classnames";
import { set } from "date-fns";
import { doc } from "firebase/firestore";

export type TypeAheadSuggestion = {
  label: string;
  category: string;
  type?: "income" | "expense";
};
type Props = {
  value: string;
  suggestions: TypeAheadSuggestion[];
  onChange: (value: TypeAheadSuggestion) => void;
  onTextChange: (value: string) => void;
  textInputProps?: Partial<TextInputProps>;
};

export const TypeAhead: FC<Props> = ({
  value,
  suggestions,
  onChange,
  onTextChange,
  textInputProps,
}) => {
  const suggestionRef = useRef<HTMLDivElement>(null);

  const [internalValue, setInternalValue] = useState<string>(value);
  const [debouncedValue] = useDebounce(internalValue, 250);

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | undefined>();
  const [currentSuggestions, setCurrentSuggestions] = useState<TypeAheadSuggestion[]>([]);
  const hasSuggestions = currentSuggestions.length > 0;

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (debouncedValue.length > 1) {
      const lowerCaseValue = debouncedValue.toLowerCase();
      const newSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.label.toLowerCase().includes(lowerCaseValue) &&
          suggestion.label !== debouncedValue
      );
      setCurrentSuggestions(newSuggestions);
    } else {
      setCurrentSuggestions([]);
    }
    setSelectedSuggestionIndex(undefined);
  }, [suggestions, debouncedValue]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        if (hasSuggestions && selectedSuggestionIndex !== undefined) {
          onChange(currentSuggestions[selectedSuggestionIndex]);
          setCurrentSuggestions([]);
          setSelectedSuggestionIndex(undefined);
        }
      }

      if ((e.key === "ArrowUp" || e.key === "ArrowLeft") && hasSuggestions) {
        e.preventDefault();
        setSelectedSuggestionIndex((prev) => {
          let el;
          if (prev === undefined || prev === 0) {
            const newIndex = currentSuggestions.length - 1;
            el = document.getElementById(currentSuggestions[newIndex].label);
            el?.scrollIntoView({ behavior: "smooth", block: "center" });
            return newIndex;
          } else {
            const newIndex = prev - 1;
            el = document.getElementById(currentSuggestions[newIndex].label);
            el?.scrollIntoView({ behavior: "smooth", block: "center" });
            return newIndex;
          }
        });
      }
      if ((e.key === "ArrowDown" || e.key === "ArrowRight") && hasSuggestions) {
        e.preventDefault();
        setSelectedSuggestionIndex((prev) => {
          let el;
          if (prev === undefined || prev === currentSuggestions.length - 1) {
            const newIndex = 0;
            el = document.getElementById(currentSuggestions[newIndex].label);
            el?.scrollIntoView({ behavior: "smooth", block: "center" });
            return newIndex;
          } else {
            const newIndex = prev + 1;
            el = document.getElementById(currentSuggestions[newIndex].label);
            el?.scrollIntoView({ behavior: "smooth", block: "center" });
            return newIndex;
          }
        });
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [selectedSuggestionIndex, hasSuggestions, currentSuggestions, debouncedValue, onChange]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Escape" && hasSuggestions) {
        e.stopPropagation();
        e.preventDefault();
        setCurrentSuggestions([]);
        setSelectedSuggestionIndex(undefined);
      }
    };
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [hasSuggestions]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    onTextChange(e.target.value);
  };

  const handleOnChange = (value: TypeAheadSuggestion) => {
    onChange(value);
    setCurrentSuggestions([]);
    setSelectedSuggestionIndex(undefined);
  };

  const handleBlur = () => {
    setCurrentSuggestions([]);
    setSelectedSuggestionIndex(undefined);
  };
  return (
    <div className="relative flex w-full">
      <TextInput
        {...textInputProps}
        onChange={handleInputChange}
        value={internalValue}
        textInputProps={{ autoComplete: "off", autoFocus: true, onBlur: handleBlur }}
      />
      {hasSuggestions && (
        <div
          ref={suggestionRef}
          className="absolute z-10 w-full mt-1 overflow-auto border rounded-md shadow-xl bg-gray-50 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200 max-h-52"
        >
          {currentSuggestions.map((suggestion, index) => {
            const wrapperClassnames = classnames(
              "p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-600",
              {
                "bg-gray-200 dark:bg-slate-600": selectedSuggestionIndex === index,
              }
            );
            return (
              <div
                id={suggestion.label}
                key={suggestion.label}
                onClick={() => {
                  handleOnChange(suggestion);
                }}
                className={wrapperClassnames}
              >
                {suggestion.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
