import { FC } from "react";
import { Link, useLocation } from "wouter";
import { Dropdown } from "../Dropdown";
import { BookOpen, List, LogOut, User } from "react-feather";
import { useAppSelector } from "../../hooks";
import { selectUser } from "../../selectors";
import { selectBudgetsArray, selectNewestThreeBudgets } from "../../selectors/budgetSelectors";

export const Nav: FC = () => {
  const [_, setLocation] = useLocation();
  const user = useAppSelector(selectUser);
  const lastThreeBudgets = useAppSelector(selectBudgetsArray);
  const lastThreeBudgetsItems = lastThreeBudgets.map((budget) => {
    return {
      label: budget.name,
      icon: <BookOpen size={20} />,
      onClick: () => {
        setLocation(`/budgets/${budget.id}`);
      },
    };
  });
  return (
    <div className="flex flex-row items-center content-center justify-between w-full px-4 py-3 border-b border-gray-300 bg-slate-50 dark:border-slate-600 dark:bg-slate-900 dark:text-slate-200">
      <p className="font-mono font-bold text-teal-600 dark:text-teal-400">fuelrail</p>
      <Dropdown
        triggerLabelString={user?.displayName ?? ""}
        triggerLabelIcon={<User size={20} className="mr-3 opacity-50" />}
        sections={[
          { heading: "Budgets", items: lastThreeBudgetsItems },

          {
            heading: "Navigation",
            items: [
              {
                label: "All Budgets",
                icon: <List size={20} />,
                onClick: () => {
                  setLocation("/budgets");
                },
              },
              {
                label: "Profile",
                onClick: () => {
                  setLocation("/profile");
                },
                icon: <User size={20} />,
              },
              {
                label: "Sign Out",
                onClick: () => {
                  setLocation("/sign-out");
                },
                icon: <LogOut size={20} />,
              },
            ],
          },
        ]}
      />
      {/* <div className="flex flex-row">
        <Link to="/budgets">Budgets</Link>
        <p>Peter</p>
      </div> */}
    </div>
  );
};
