export const firebaseConfig = {
  apiKey: "AIzaSyABhrRNoFrJmSwOPkVmYVWjH0RQtZmfcpg",
  authDomain: "fuelrail-e2173.firebaseapp.com",
  projectId: "fuelrail-e2173",
  storageBucket: "fuelrail-e2173.appspot.com",
  messagingSenderId: "403902788740",
  appId: "1:403902788740:web:770fa1c053768c6c1abed1",
  measurementId: "G-PVZJ9L00YK",
};

console.log("🔥 Firebase: Initializing App...");
