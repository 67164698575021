import { FC, ReactElement } from "react";
import classnames from "classnames";

export type Props = {
  label?: string;
  active?: boolean;
  type: "primary" | "secondary" | "warning";
  disabled?: boolean;
  onClick: () => void;
  additionalClassnames?: string;
  icon?: ReactElement;
  tooltipId?: string;
};

export const Button: FC<Props> = ({
  label,
  type,
  disabled,
  onClick,
  icon,
  additionalClassnames,
  active,
  tooltipId,
}) => {
  const wrapperClassnames = classnames(
    "py-2 px-4 transition-colors border rounded-xl flex flex-row items-center justify-between",
    additionalClassnames,
    {
      "hover:shadow-lg": !disabled,
      "opacity-50 cursor-not-allowed": disabled,

      "bg-teal-200 text-green-950 dark:bg-teal-800 dark:text-green-200 border-teal-300 dark:border-teal-900":
        type === "primary",
      "hover:bg-teal-400 dark:hover:bg-teal-700": type === "primary" && !disabled,
      "bg-teal-400 dark:bg-teal-700": type === "primary" && active,

      "bg-gray-100 text-gray-900 dark:bg-slate-800 dark:text-slate-200 border-gray-300 dark:border-slate-600":
        type === "secondary",
      "hover:bg-gray-200 dark:hover:bg-slate-700 ": type === "secondary" && !disabled,
      "bg-gray-300 dark:bg-slate-700": type === "secondary" && active,

      "bg-red-400 text-red-50 dark:bg-red-900 dark:text-red-200 border-red-400 dark:border-red-800":
        type === "warning",
      "hover:bg-red-500 dark:hover:bg-red-700": type === "warning" && !disabled,
      "bg-red-500 dark:bg-red-700": type === "warning" && active,
    }
  );
  return (
    <button
      data-tooltip-id={tooltipId}
      disabled={disabled}
      className={wrapperClassnames}
      onClick={onClick}
      type="button"
    >
      {icon && <span className="opacity-75">{icon}</span>}
      {label ?? null}
    </button>
  );
};
