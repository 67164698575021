import { FC } from "react";
import { Budget, BudgetItem, BudgetRow } from "../../../slices";
import { createDefaultRows, formatCurrency, makeCalendarChartDataForMonth } from "../../../utils";
import { useAppSelector } from "../../../hooks";
import { selectLinkedBudgets } from "../../../selectors/budgetSelectors";
import { ResponsiveCalendar } from "@nivo/calendar";
import { selectViewResolution } from "../../../selectors/uiSelectors";
import { calendarColorProps, darkTheme } from "../themes";

type Props = {
  budget: Budget;
  currentYear: number;
  currentMonth: number;
};

export const BudgetCalendarChart: FC<Props> = ({ budget, currentMonth, currentYear }) => {
  const linkedBudgetSelector = selectLinkedBudgets(budget.id);
  const linkedBudgets = useAppSelector(linkedBudgetSelector) ?? [];
  const fromString = new Date(currentYear, currentMonth - 1, 1, 0, 0, 0);
  const toString = new Date(currentYear, 11, 31, 1, 0, 0); //`${currentYear}-12-31`;

  const chartData = Array(12)
    .fill(0)
    .map((_, i) => {
      return makeCalendarChartDataForMonth([budget, ...linkedBudgets], currentYear, i + 1);
    })
    .flat();

  //   const generateCalendarData = () => {
  //     let abc: any = {};
  //     for (let i = 1; i <= 12; i++) {
  //       const rows = createDefaultRows(budget, "month", currentYear, i);
  //       const linkedRows = (linkedBudgets ?? []).reduce((acc, curr) => {
  //         return [...acc, ...createDefaultRows(curr, "month", currentYear, i)];
  //       }, [] as BudgetRow[]);
  //       [...rows, ...linkedRows].forEach((row) => {
  //         row.items.forEach((item) => {
  //             if (!abc[`${item.year}-${item.month}-${item.day}`]) {
  //               abc[`${item.year}-${item.month}`] = [];
  //             }
  //         })
  //       })
  //       console.log([...rows, ...linkedRows]);
  //     }
  //   };
  //   generateCalendarData();
  console.log(chartData);
  return (
    <div className="relative flex flex-col p-3 bg-white border border-gray-300 shadow-md scroll-smooth rounded-xl dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200">
      <div className="p-4 font-bold">
        <p>{budget.name}</p>
        <p className="text-sm font-normal text-gray-500">{budget.description}</p>
      </div>
      <div className=" min-h-96 h-96">
        <ResponsiveCalendar
          {...calendarColorProps()}
          data={chartData}
          align="center"
          from={fromString}
          to={toString}
          margin={{ top: 0, bottom: 0, left: 40 }}
          direction="horizontal"
          dayBorderWidth={1}
          monthSpacing={20}
          daySpacing={4}
          monthBorderWidth={0}
          monthLegendOffset={10}
          yearLegendOffset={10}
          tooltip={({ day, value }) => {
            return (
              <div className="p-2 bg-white border border-gray-300 shadow-md dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200">
                <p>{`${day}: ${formatCurrency(value, budget.currencyShortCode ?? "USD")}`}</p>
              </div>
            );
          }}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              // translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 50,
              itemDirection: "right-to-left",
            },
          ]}
        />
      </div>
    </div>
  );
};
