import { FC, useState } from "react";
import classnames from "classnames";
import { BudgetItem } from "../../../slices";
import { CELL_CLASSNAMES } from "./cellClassnames";
import { formatCurrency } from "../../../utils";
import { selectViewResolution } from "../../../selectors/uiSelectors";
import { useAppSelector } from "../../../hooks";

type Props = {
  cell: BudgetItem;
  sticky?: "left" | "right";
  index: number;
  currencyShortCode: string;
};

export const FooterCellRenderer: FC<Props> = ({ cell, sticky, index, currencyShortCode }) => {
  const viewResolution = useAppSelector(selectViewResolution);

  const systemDate = new Date();
  const systemYear = systemDate.getFullYear();
  const systemMonth = systemDate.getMonth() + 1;
  const systemDay = systemDate.getDate();

  const isSameMonth = cell.year === systemYear && cell.monthIndex === systemMonth;
  const isSameDay =
    viewResolution === "month" ? isSameMonth && cell.dayIndex === systemDay : isSameMonth;

  const wrapperClassnames = classnames(CELL_CLASSNAMES.base, CELL_CLASSNAMES.footer, {
    [CELL_CLASSNAMES.stickyLeft]: sticky === "left",
    [CELL_CLASSNAMES.stickyRight]: sticky === "right",
    [CELL_CLASSNAMES.positive]: (cell?.value ?? 0) > 0,
    [CELL_CLASSNAMES.negative]: (cell?.value ?? 0) < 0,
    [CELL_CLASSNAMES.empty]: (cell?.value ?? 0) === 0 && index !== 0,
    [CELL_CLASSNAMES.header]: index === 0,
    [CELL_CLASSNAMES.dataBase]: index !== 0,
    [CELL_CLASSNAMES.currentDate]: isSameDay && isSameMonth,
  });
  const formattedValue = formatCurrency(cell.value ?? 0, currencyShortCode);
  return <div className={wrapperClassnames}>{index === 0 ? cell.label : formattedValue}</div>;
};
