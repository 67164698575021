import { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  Budget,
  BudgetRow,
  setCollapsedRowIds,
  setCurrentlySelectedCell,
  ViewResolution,
} from "../../slices";
import {
  createDefaultRows,
  createFooterRow,
  createHeaderRow,
} from "../../utils";

import { useAppDispatch, useAppSelector } from "../../hooks";

import { selectUserUid } from "../../selectors";
import { Tooltip } from "react-tooltip";
import { TOOLTIP_IDS } from "../../consts";
import {
  selectCollapsedRowIds,
  selectCurrentlySelectedCell,
  selectCurrentMonth,
  selectCurrentYear,
  selectViewResolution,
} from "../../selectors/uiSelectors";
import {
  DataRowRenderer,
  FooterRowRenderer,
  HeaderRowRenderer,
  InfoRowRenderer,
} from "./RowRenderers";
import { selectLinkedBudgets } from "../../selectors/budgetSelectors";
import classnames from "classnames";
import { PortalSubGrid } from "./PortalSubGrid";

type Props = {
  budget: Budget;
};

export const BudgetTable: FC<Props> = ({ budget }) => {
  const dispatch = useAppDispatch();
  const uid = useAppSelector(selectUserUid);
  const viewResolution = useAppSelector(selectViewResolution);
  const currentYear = useAppSelector(selectCurrentYear);
  const currentMonth = useAppSelector(selectCurrentMonth);

  const collapsedRowIds = useAppSelector(selectCollapsedRowIds);

  const linkedBudgetSelector = selectLinkedBudgets(budget.id);
  const linkedBudgets = useAppSelector(linkedBudgetSelector);
  const selectedCell = useAppSelector(selectCurrentlySelectedCell);

  const wrapperClassnames = classnames(
    "bg-white scroll-smooth border transition-all border-gray-300 shadow-md rounded-xl dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200",
    {
      "overflow-auto": !selectedCell || !selectedCell.shouldRenderInSubGrid,
      "overflow-hidden scale-90 opacity-50":
        selectedCell?.shouldRenderInSubGrid,
    }
  );
  useEffect(() => {
    const systemDate = new Date();
    const systemYear = systemDate.getFullYear();
    const systemMonth = systemDate.getMonth() + 1;
    const systemDay = systemDate.getDate();

    let monthIndex = systemMonth;
    let dayIndex = systemDay;

    if (viewResolution === "year") {
      dayIndex = 1;
      if (systemYear !== currentYear) {
        monthIndex = 1;
      }
    }
    if (viewResolution === "month") {
      if (systemMonth !== currentMonth) {
        dayIndex = 1;
        monthIndex = currentMonth;
      }
    }
    const el = document.getElementById(
      `main-grid-header-cell-${systemYear}-${monthIndex}-${dayIndex}`
    );
    console.log(
      "scrolling to",
      `header-cell-${systemYear}-${monthIndex}-${dayIndex}`
    );
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, [viewResolution, currentMonth, currentYear]);

  if (!uid || !budget?.id) return null;

  const handleInfoRowClick = (rowId: string) => {
    if (selectedCell?.originalBudget.id === rowId) {
      dispatch(setCurrentlySelectedCell(undefined));
    }
    dispatch(setCollapsedRowIds(rowId));
  };

  const headerRow = createHeaderRow(
    budget,
    viewResolution,
    currentMonth,
    currentYear
  );
  const defaultRows = createDefaultRows(
    budget,
    viewResolution,
    currentYear,
    currentMonth
  );

  const linkedRows = (linkedBudgets ?? [])
    .filter((f) => (f.items ?? []).length > 0)
    .reduce(
      (acc, curr) => {
        const rows = createDefaultRows(
          curr,
          viewResolution,
          currentYear,
          currentMonth
        );
        const footer = createFooterRow(curr, rows);
        return {
          rows: [...acc.rows, footer],
          data: [...acc.data, { budget: curr, rows }],
        };
      },
      { rows: [], data: [] } as {
        rows: BudgetRow[];
        data: { budget: Budget; rows: BudgetRow[] }[];
      }
    );

  const footerRow = createFooterRow(budget, [
    ...defaultRows,
    ...linkedRows.rows,
  ]);

  return (
    <>
      <div className={wrapperClassnames}>
        <div className="grid divide-y divide-gray-300 auto-rows-max dark:divide-slate-600">
          <HeaderRowRenderer viewResolution={viewResolution} row={headerRow} />
          <DataRowRenderer budget={budget} rows={defaultRows} />
          {linkedRows.data.map(({ budget, rows }, index) => {
            const isCollapsed = collapsedRowIds?.includes(budget.id);
            const canCollapse = true; //rows.length > 1;
            const footerRow = linkedRows.rows[index];
            // console.log(linkedRows.rows[index]);
            if (isCollapsed) {
              footerRow.items[0].label = `${budget.name} Total`;
            }
            return (
              <InfoRowRenderer
                key={index}
                budget={budget}
                onClick={() =>
                  canCollapse ? handleInfoRowClick(budget.id) : null
                }
                canCollapse={canCollapse}
                isCollapsed={isCollapsed}
                footerRow={footerRow}
                dataRows={rows}
              />
            );
          })}

          <PortalSubGrid selectedCell={selectedCell} />

          <FooterRowRenderer budget={budget} row={footerRow} />
        </div>
        <Tooltip
          className=" !bg-gray-300 !opacity-100 !text-gray-600"
          id={TOOLTIP_IDS.INFO_ONE_OFF_CELL.id}
          content={TOOLTIP_IDS.INFO_ONE_OFF_CELL.content}
          place="top"
        />
        <Tooltip
          className=" !bg-gray-300 !opacity-100 !text-gray-600 !z-30"
          id={TOOLTIP_IDS.INFO_ONE_OFF_REMOVE.id}
          content={TOOLTIP_IDS.INFO_ONE_OFF_REMOVE.content}
          place="top"
        />
        <Tooltip
          className=" !bg-gray-300 !opacity-100 !text-gray-600 !z-30"
          id={TOOLTIP_IDS.INFO_ONE_OFF_SAVE_ONCE.id}
          content={TOOLTIP_IDS.INFO_ONE_OFF_SAVE_ONCE.content}
          place="top"
        />
        <Tooltip
          className=" !bg-gray-300 !opacity-100 !text-gray-600 !z-30"
          id={TOOLTIP_IDS.INFO_ONE_OFF_SAVE_REPEATING.id}
          content={TOOLTIP_IDS.INFO_ONE_OFF_SAVE_REPEATING.content}
          place="top"
        />
        <Tooltip
          className=" !bg-gray-300 !opacity-100 !text-gray-600 !z-30"
          id={TOOLTIP_IDS.INFO_ONE_OFF_CANCEL.id}
          content={TOOLTIP_IDS.INFO_ONE_OFF_CANCEL.content}
          place="top"
        />
      </div>
      <div className="flex flex-row items-center justify-end p-2">
        <div className="flex flex-row items-center mr-6">
          <div className="w-2 h-4 mr-3 bg-blue-600 rounded-full dark:bg-blue-500" />{" "}
          One Off
        </div>
        <div className="flex flex-row items-center">
          <div className="w-2 h-4 mr-3 bg-pink-500 rounded-full dark:bg-pink-400" />{" "}
          Contains Multiple Values
        </div>
      </div>
    </>
  );
};
