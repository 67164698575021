import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  getFirestore,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { app } from "../App";
import { doc, setDoc, getDocs } from "firebase/firestore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Budget, BudgetItem, BudgetItemOneOff } from "../slices";

export const addBudgetItem = createAsyncThunk(
  "budgets/addBudgetItem",
  async ({ uid, budgetId, item }: { uid: string; budgetId: string; item: BudgetItem }) => {
    const db = await getFirestore(app);
    const ref = doc(collection(db, "users", uid, "budgets", budgetId, "items"));
    const itemWithId = { ...item, id: ref.id, budgetId };

    await setDoc(ref, itemWithId);
    return { budgetId, item: itemWithId };
  }
);

export const getAllBudgetItemsForId = createAsyncThunk(
  "budgets/getAllBudgetItemsForId",
  async ({ uid, budgetId }: { uid: string; budgetId: string }) => {
    const db = await getFirestore(app);
    const ref = await query(
      collection(db, "users", uid, "budgets", budgetId, "items"),
      orderBy("type", "desc")
    );

    const docs = await getDocs(ref);
    return {
      items: docs.docs.map((doc) => doc.data() as BudgetItem),
      budgetId,
    };
  }
);

export const removeBudgetItem = createAsyncThunk(
  "budgets/removeBudgetItem",
  async ({ uid, budgetId, itemId }: { uid: string; budgetId: string; itemId: string }) => {
    const db = await getFirestore(app);

    const ref = doc(db, "users", uid, "budgets", budgetId, "items", itemId);

    await deleteDoc(ref);
    return { budgetId, itemId: itemId };
  }
);

export const updateBudgetItem = createAsyncThunk(
  "budgets/updateBudgetItem",
  async ({ uid, budgetId, item }: { uid: string; budgetId: string; item: BudgetItem }) => {
    const db = await getFirestore(app);

    const ref = doc(collection(db, "users", uid, "budgets", budgetId, "items"), item.id);
    await updateDoc(ref, item);
    return { budgetId, item };
  }
);
export const getAllBudgetItemOneOffsForId = createAsyncThunk(
  "budgets/getAllBudgetItemOneOffsForId",
  async ({ uid, budgetId, itemId }: { uid: string; budgetId: string; itemId: string }) => {
    const db = await getFirestore(app);
    const ref = await collection(db, "users", uid, "budgets", budgetId, "items", itemId, "oneOffs");

    const docs = await getDocs(ref);
    return {
      oneOffs: docs.docs.map((doc) => doc.data() as BudgetItemOneOff),
      budgetId,
      itemId,
    };
  }
);
export const addBudgetItemOneOffs = createAsyncThunk(
  "budgets/addBudgetItemOneOffs",
  async ({
    uid,
    budgetId,
    itemId,
    oneOffs,
  }: {
    uid: string;
    budgetId: string;
    itemId: string;
    oneOffs: BudgetItemOneOff[];
  }) => {
    const db = await getFirestore(app);
    const batch = writeBatch(db);

    for await (const oneOff of oneOffs) {
      const oneOffRef = doc(
        db,
        "users",
        uid,
        "budgets",
        budgetId,
        "items",
        itemId,
        "oneOffs",
        oneOff.id
      );
      const oneOffWithId = { ...oneOff, id: oneOffRef.id };
      batch.set(oneOffRef, oneOffWithId);
    }

    await batch.commit();

    return { budgetId, itemId, oneOffs };
  }
);

export const updateBudgetItemOneOff = createAsyncThunk(
  "budgets/updateBudgetItemOneOff",
  async ({
    uid,
    budgetId,
    itemId,
    oneOff,
  }: {
    uid: string;
    budgetId: string;
    itemId: string;
    oneOff: BudgetItemOneOff;
  }) => {
    const db = await getFirestore(app);
    if (!oneOff.id) return;
    const ref = doc(db, "users", uid, "budgets", budgetId, "items", itemId, "oneOffs", oneOff.id);

    await updateDoc(ref, oneOff);

    return { budgetId, itemId, oneOff };
  }
);

export const removeBudgetItemOneOff = createAsyncThunk(
  "budgets/removeBudgetItemOneOff",
  async ({
    uid,
    budgetId,
    itemId,
    oneOffId,
  }: {
    uid: string;
    budgetId: string;
    itemId: string;
    oneOffId: string;
  }) => {
    const db = await getFirestore(app);
    const ref = doc(db, "users", uid, "budgets", budgetId, "items", itemId, "oneOffs", oneOffId);
    await deleteDoc(ref);

    return { budgetId, itemId, oneOffId };
  }
);
