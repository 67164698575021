import { FC, ReactElement } from "react";
import { Dialog, DialogPanel, DialogBackdrop } from "@headlessui/react";
import classnames from "classnames";

type Props = {
  children: ReactElement | ReactElement[];
  isOpen: boolean;
  onClose: () => void;
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "full";
};

export const Modal: FC<Props> = ({ children, isOpen, onClose, size }) => {
  const panelClassnames = classnames(
    "w-full rounded-2xl bg-white shadow-xl dark:bg-slate-900 dark:text-slate-200 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0",
    {
      "max-w-sm": size === "sm",
      "max-w-md": size === "md" || !size,
      "max-w-lg": size === "lg",
      "max-w-xl": size === "xl",
      "max-w-2xl": size === "2xl",
      "max-w-3xl": size === "3xl",
      "max-w-full": size === "full",
    }
  );
  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-40 focus:outline-none"
      onClose={onClose}
      transition
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 z-40 bg-gray-500 bg-opacity-75 dark:bg-slate-950 dark:bg-opacity-75 transition-opacity duration-300 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 z-40 w-screen overflow-y-auto ">
        <div className="flex items-center justify-center min-h-full p-4 ">
          <DialogPanel transition className={panelClassnames}>
            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
