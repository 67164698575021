import { FC, ReactElement } from "react";
import classnames from "classnames";
import { Button } from "./Button";
import type { Props as ButtonProps } from "./Button";

type Props = {
  buttonProps: {
    onClick: () => void;
    label?: string;
    icon?: ReactElement;
    disabled?: boolean;
    active?: boolean;
  }[];
  type: ButtonProps["type"];
  additionalClassnames?: string;
};

export const ButtonGroup: FC<Props> = ({ buttonProps, type, additionalClassnames }) => {
  const wrapperClassnames = classnames("flex flex-row", additionalClassnames);
  const buttons = buttonProps.map((buttonProp, index) => {
    const buttonClassnames = classnames("mr-0 ml-0", {
      "rounded-l-xl rounded-r-none": index === 0,
      "border-r-0": index >= 0 && index < buttonProps.length - 1,
      "rounded-r-xl rounded-l-none": index === buttonProps.length - 1,
      "rounded-l-none rounded-r-none border-r": index > 0 && index < buttonProps.length - 1,
    });

    return (
      <Button key={index} {...buttonProp} type={type} additionalClassnames={buttonClassnames} />
    );
  });
  return <div className={wrapperClassnames}>{buttons}</div>;
};
