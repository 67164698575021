import { Route, Switch, useLocation } from "wouter";
import { firebaseConfig, routes } from "./configs";
import { Nav } from "./components";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

import { useAppDispatch } from "./hooks";
import { setUser } from "./slices";
import { Suspense } from "react";

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

function App() {
  const [_, setLocation] = useLocation();

  const auth = getAuth(app);
  const dispatch = useAppDispatch();

  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch(
        setUser({
          loggedIn: true,
          data: {
            uid: user.uid,
            email: user.email ?? "n/a",
            photoURL: user.photoURL,
            providerId: user.providerId,
            displayName: user.displayName,
            budgets: [],
          },
        })
      );
    } else {
      setLocation("/auth");
    }
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="flex flex-col flex-1 max-h-full">
        <Nav />
        <Switch>
          {routes.map((route, index) => (
            <Route {...route} key={index} />
          ))}
          <Route>404: No such page!</Route>
        </Switch>
      </div>
    </Suspense>
  );
}

export default App;
