import { FC } from "react";
import classnames from "classnames";
import { BudgetRow, ViewResolution } from "../../../slices";
import { ROW_CLASSNAMES } from "./rowClassnames";
import { HeaderCellRenderer } from "../CellRenderers";

type Props = {
  row: BudgetRow;
  viewResolution: ViewResolution;
  isSubGrid?: boolean;
};

export const HeaderRowRenderer: FC<Props> = ({ row, viewResolution, isSubGrid }) => {
  const wrapperClassnames = classnames(ROW_CLASSNAMES.base, ROW_CLASSNAMES.header);
  return (
    <div className={wrapperClassnames}>
      {(row?.items ?? []).map((cell, index) => {
        const sticky = index === 0 ? "left" : index === row.items.length - 1 ? "right" : undefined;
        return (
          <HeaderCellRenderer
            viewResolution={viewResolution}
            key={index}
            cell={cell}
            sticky={sticky}
            isSubGrid={isSubGrid}
          />
        );
      })}
    </div>
  );
};
