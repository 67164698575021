export const getLang = () => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

export const formatCurrency = (value: number | string | undefined, currencyShortCode: string) => {
  const parsed = value ? (typeof value === "string" ? parseFloat(value) : value) : 0;

  const locale = getLang();

  const formatter = Intl.NumberFormat(locale, { style: "currency", currency: currencyShortCode });
  return formatter.format(parsed);
  //   if (fixed[0] === "-") {
  //     const reversed = parsed * -1;
  //     return `-${currencyShortCode}${reversed.toFixed(2)}`;
  //   }

  //   return `${currencyShortCode}${fixed}`;
};
