import { FC, useCallback, useEffect, useState } from "react";

import classnames from "classnames";
import {
  Budget,
  BudgetItemOneOff,
  BudgetRow,
  SelectedCell,
  setCurrentlySelectedCell,
} from "../../../slices";
import { ROW_CLASSNAMES } from "./rowClassnames";
import { DataCellRenderer, FooterCellRenderer, RowHeaderCellRenderer } from "../CellRenderers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { removeBudgetItemOneOff, addBudgetItemOneOffs, updateBudgetItemOneOff } from "../../../api";
import { selectUserUid } from "../../../selectors";
import { selectCurrentlySelectedCell, selectViewResolution } from "../../../selectors/uiSelectors";

type Props = {
  budget: Budget;
  rows: BudgetRow[];
  isSubGrid?: boolean;
};

const advanceSelectedCell = (currentCoordinates: number[], rows: BudgetRow[]) => {
  const [rowIndex, cellIndex] = currentCoordinates;

  if (cellIndex === rows[rowIndex].items.length - 2) {
    if (rowIndex === rows.length - 1) {
      return [];
    } else {
      return [rowIndex + 1, 1];
    }
  } else {
    return [rowIndex, cellIndex + 1];
  }
};

const retreatSelectedCell = (currentCoordinates: number[], rows: BudgetRow[]) => {
  const [rowIndex, cellIndex] = currentCoordinates;

  if (cellIndex === 1) {
    if (rowIndex === 0) {
      return [];
    } else {
      return [rowIndex - 1, rows[rowIndex - 1].items.length - 2];
    }
  } else {
    return [rowIndex, cellIndex - 1];
  }
};

export const DataRowRenderer: FC<Props> = ({ rows, budget, isSubGrid }) => {
  const dispatch = useAppDispatch();

  const uid = useAppSelector(selectUserUid);
  const viewResolution = useAppSelector(selectViewResolution);

  const [localSelectedCell, setLocalSelectedCell] = useState<SelectedCell | undefined>();
  const reduxSelectedCell = useAppSelector(selectCurrentlySelectedCell);

  const selectedCell = isSubGrid ? localSelectedCell : reduxSelectedCell;
  /** TODO:
   *
   * - use new handleCellClick below to dispatch selected cell to redux
   * - move subGrid render to BudgetTable or BudgetView
   * - clean up and remove old code
   */
  const handleCellClick = (coords: number[]) => {
    const [rowIndex, cellIndex] = coords;
    if (rowIndex === selectedCell?.coords[0] && cellIndex === selectedCell?.coords[1]) {
      dispatch(setCurrentlySelectedCell(undefined));
      return;
    }
    const rowCell = rows[rowIndex]?.items[cellIndex];
    const originalCell = (budget?.items ?? []).find((f) => f.id === rowCell.id);

    if (!rowCell || !originalCell) return;

    const containsMultiple =
      rowCell.type === "oneOffMultiple" ||
      (originalCell.repeats === "daily" && viewResolution === "year");

    const isSameCellAsClicked =
      selectedCell?.coords[0] === rowIndex && selectedCell?.coords[1] === cellIndex;

    const newSelectedCell: SelectedCell | undefined = !isSameCellAsClicked
      ? {
          coords,
          rowCell,
          originalCell,
          originalBudget: budget,
          shouldRenderInSubGrid: containsMultiple,
          subGridViewResolution: "month",
        }
      : undefined;

    if (isSubGrid) {
      // don't dispatch as we're in a sub grid
      // ...this will cause problems later...
      setLocalSelectedCell(newSelectedCell);
    } else {
      dispatch(setCurrentlySelectedCell(newSelectedCell));
    }
  };

  // const _handleCellClick = useCallback(
  //   (coordinates: number[]) => {
  //     const [rowIndex, cellIndex] = coordinates;
  //     if (isEditing[0] === rowIndex && isEditing[1] === cellIndex) {
  //       setIsEditing([]);
  //       onUnlockScroll?.();
  //     } else {
  //       const nextSelectedCell = rows[rowIndex]?.items[cellIndex];

  //       if (
  //         nextSelectedCell &&
  //         (nextSelectedCell.type === "oneOffMultiple" ||
  //           (nextSelectedCell.repeats === "daily" && viewResolution === "year"))
  //       ) {
  //         onLockScroll?.();
  //       } else {
  //         onUnlockScroll?.();
  //       }
  //       setIsEditing([rowIndex, cellIndex]);
  //     }
  //   },
  //   [isEditing, onLockScroll, onUnlockScroll, rows, viewResolution]
  // );

  // useEffect(() => {
  //   const handleKeyPress = (e: KeyboardEvent) => {
  //     // Detect shift+tab keypress

  //     if (e.key === "Tab") {
  //       e.preventDefault();
  //       e.stopPropagation();

  //       if (e.shiftKey) {
  //         handleCellClick(retreatSelectedCell(isEditing, rows));
  //       } else {
  //         handleCellClick(advanceSelectedCell(isEditing, rows));
  //       }
  //     }

  //     if (e.key === "Escape") {
  //       handleCellClick([]);
  //     }
  //   };
  //   if (isEditing.length > 0) {
  //     document.addEventListener("keydown", handleKeyPress);
  //   }

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [handleCellClick, isEditing, rows]);

  if (!uid || !budget) return null;

  const handleAddOneOff = async (oneOff: BudgetItemOneOff) => {
    if (!selectedCell?.rowCell.id) return;
    const indexOfOneOff = selectedCell.rowCell.oneOffs?.findIndex((o) => o.id === oneOff.id);

    if (indexOfOneOff === -1 || !indexOfOneOff) {
      // add new
      await dispatch(
        addBudgetItemOneOffs({
          uid,
          budgetId: budget.id,
          itemId: selectedCell.rowCell.id,
          oneOffs: [oneOff],
        })
      );
    } else {
      // update existing
      await dispatch(
        updateBudgetItemOneOff({
          uid,
          budgetId: budget.id,
          itemId: selectedCell.rowCell.id,
          oneOff,
        })
      );
    }
    if (isSubGrid) {
      setLocalSelectedCell(undefined);
    } else {
      dispatch(setCurrentlySelectedCell(undefined));
    }
  };

  const handleRemoveOneOff = async (oneOff: BudgetItemOneOff) => {
    if (!selectedCell?.rowCell.id) return;
    await dispatch(
      removeBudgetItemOneOff({
        uid,
        budgetId: budget.id,
        itemId: selectedCell.rowCell.id,
        oneOffId: oneOff.id,
      })
    );
  };
  // if (rows.length === 0) return <div className="flex items-center justify-center p-24">empty</div>;
  return rows.map((row, rowIndex) => {
    const wrapperClassnames = classnames(ROW_CLASSNAMES.base, {});
    const rowLength = (row.items ?? []).length;
    return (
      <div className={wrapperClassnames} key={rowIndex}>
        {(row?.items ?? []).map((cell, index) => {
          const isCellBeingEdited =
            selectedCell?.rowCell.id === cell.id &&
            selectedCell?.coords[0] === rowIndex &&
            selectedCell?.coords[1] === index;

          if (index === 0) {
            return <RowHeaderCellRenderer key={index} cell={cell} sticky="left" />;
          }

          if (index === rowLength - 1) {
            return (
              <FooterCellRenderer
                key={index}
                index={index}
                cell={cell}
                currencyShortCode={budget.currencyShortCode ?? "USD"}
                sticky={index === rowLength - 1 ? "right" : undefined}
              />
            );
          }

          return (
            <DataCellRenderer
              isEditing={isCellBeingEdited}
              onCellClick={() => handleCellClick([rowIndex, index])}
              onAddOneOffs={handleAddOneOff}
              onRemoveOneOffs={handleRemoveOneOff}
              key={index}
              cell={cell}
              currencyShortCode={budget.currencyShortCode ?? "USD"}
              isSubGrid={isSubGrid}
            />
          );
        })}
      </div>
    );
  });
};
