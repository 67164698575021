import { FC, useEffect, useState } from "react";
import classnames from "classnames";

type Props = {
  label?: string;
  value: number;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  min?: number;
  max?: number;
  prefix?: string;
  additionalClasses?: string;
  disabled?: boolean;
};

export const NumberInput: FC<Props> = ({
  label,
  value,
  min,
  max,
  placeholder,
  onChange,
  prefix,
  additionalClasses,
  disabled,
}) => {
  const [localValue, setLocalValue] = useState<string>(String(value));
  const [valid, setValid] = useState<boolean>(true);

  useEffect(() => {
    setLocalValue(String(value));
  }, [value]);

  const wrapperClassnames = classnames(
    "flex flex-col items-stretch w-full mb-3",
    additionalClasses,
    {
      "opacity-50": disabled,
    }
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const replaced = e.target.value.replace(/[^0-9-]+/g, "");
    const number = Number(replaced);

    setLocalValue(replaced);
    // The real pattern you are looking for
    const pattern = /([-])?([0-9]+)/g;
    const matches = replaced.match(pattern);

    if (isNaN(number)) {
      // debugger;
      return;
    }
    if (matches) {
      onChange?.(e);
    }
    if (!matches) {
      onChange?.(undefined);
    }
  };
  return (
    <div className={wrapperClassnames}>
      {label && (
        <label htmlFor={label} className="mb-1 text-sm font-semibold">
          {label}
        </label>
      )}
      <div className="flex flex-row items-center border rounded-xl dark:text-slate-600 dark:border-slate-600">
        <input
          disabled={disabled}
          type="text"
          id={label}
          value={localValue}
          onChange={handleChange}
          placeholder={placeholder}
          className="flex flex-1 w-full p-2 font-mono rounded-xl dark:bg-slate-800 dark:text-slate-200"
        />
      </div>
    </div>
  );
};
