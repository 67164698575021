import { FC, InputHTMLAttributes, useState } from "react";
import classnames from "classnames";
import { text } from "stream/consumers";

export type Props = {
  label?: string;
  year?: number;
  month?: number;
  day?: number;
  onChange: (e?: string) => void;
  placeholder?: string;
  additionalClasses?: string;
  textInputProps?: Partial<InputHTMLAttributes<HTMLInputElement>>;
  type: "date" | "month";
};

export const DateInput: FC<Props> = ({
  label,
  year,
  month,
  day,
  placeholder,
  onChange,
  additionalClasses,
  textInputProps,
  type,
}) => {
  const wrapperClassnames = classnames(
    "flex flex-col items-stretch w-full mb-3",
    additionalClasses
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const pattern = type === "date" ? /(\d{4})-(\d{2})-(\d{2})/ : /(\d{4})-(\d{2})/;
    const matches = value.match(pattern);

    if (matches) {
      const correctedValue = type === "date" ? matches[0] : `${matches[0]}-01`;
      onChange(correctedValue);
    } else {
      onChange(undefined);
    }
  };

  const correctedYear = `${year}`.padStart(4, "0");
  const correctedMonth = `${month}`.padStart(2, "0");
  const correctedDay = `${day}`.padStart(2, "0");
  const correctedValue =
    type === "date"
      ? `${correctedYear}-${correctedMonth}-${correctedDay}`
      : `${correctedYear}-${correctedMonth}`;
  return (
    <div className={wrapperClassnames}>
      {label && (
        <label htmlFor={label} className="mb-1 text-sm font-semibold">
          {label}
        </label>
      )}
      <input
        {...textInputProps}
        type={type}
        id={label}
        value={correctedValue}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full p-2 border rounded-xl dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
      />
    </div>
  );
};
