import { FC } from "react";
import classnames from "classnames";
import { BudgetItem, ViewResolution } from "../../../slices";
import { CELL_CLASSNAMES } from "./cellClassnames";

type Props = {
  cell: BudgetItem;
  sticky: "left" | "right" | undefined;
  viewResolution: ViewResolution;
  isSubGrid?: boolean;
};

export const HeaderCellRenderer: FC<Props> = ({ cell, sticky, viewResolution, isSubGrid }) => {
  const systemDate = new Date();
  const systemYear = systemDate.getFullYear();
  const systemMonth = systemDate.getMonth() + 1;
  const systemDay = systemDate.getDate();

  const isSameMonth = cell.year === systemYear && cell.monthIndex === systemMonth;
  const isSameDay =
    viewResolution === "month" ? isSameMonth && cell.dayIndex === systemDay : isSameMonth;

  const wrapperClassnames = classnames(CELL_CLASSNAMES.base, CELL_CLASSNAMES.header, {
    [CELL_CLASSNAMES.stickyLeft]: sticky === "left",
    [CELL_CLASSNAMES.stickyRight]: sticky === "right",
    [CELL_CLASSNAMES.currentDate]: isSameDay && isSameMonth,
  });

  const id = `${isSubGrid ? "sub-grid" : "main-grid"}-header-cell-${cell.year}-${cell.monthIndex}-${
    cell.dayIndex
  }`;

  return (
    <div id={id} className={wrapperClassnames}>
      {cell.label}
    </div>
  );
};
