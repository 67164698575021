import { FC, InputHTMLAttributes } from "react";
import classnames from "classnames";
import { text } from "stream/consumers";

export type Props = {
  label?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  additionalClasses?: string;
  textInputProps?: Partial<InputHTMLAttributes<HTMLInputElement>>;
};

export const TextInput: FC<Props> = ({
  label,
  value,
  placeholder,
  onChange,
  additionalClasses,
  textInputProps,
}) => {
  const wrapperClassnames = classnames(
    "flex flex-col items-stretch w-full mb-3",
    additionalClasses
  );
  return (
    <div className={wrapperClassnames}>
      {label && (
        <label htmlFor={label} className="mb-1 text-sm font-semibold">
          {label}
        </label>
      )}
      <input
        {...textInputProps}
        type="text"
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="flex flex-1 w-full p-2 border rounded-xl dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
      />
    </div>
  );
};
