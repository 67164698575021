import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Timestamp } from "firebase/firestore";
import {
  addBudgetItem,
  addBudgetItemOneOffs,
  createNewBudget,
  deleteBudget,
  getAllBudgetItemsForId,
  getAllBudgets,
  removeBudgetItem,
  removeBudgetItemOneOff,
  updateBudget,
  updateBudgetItem,
  linkBudgets,
  removeLinkedBudget,
  updateBudgetItemOneOff,
  getAllBudgetItemOneOffsForId,
} from "../api";

export type BudgetItemType =
  | "info"
  | "income"
  | "expense"
  | "header"
  | "footer"
  | "rowHeader"
  | "rowFooter"
  | "empty"
  | "hidden"
  | "oneOff"
  | "oneOffMultiple";

export type BudgetRowType = "header" | "footer" | "info" | "default" | "empty";

export type BudgetItemOneOff = {
  id: string;
  dayIndex?: number;
  monthIndex?: number;
  value: number;
  year: number;
};
export type BudgetItem = {
  id?: string;
  description?: string;
  label?: string;
  value?: number;
  monthIndex: number;
  dayIndex: number;
  year: number;

  endMonthIndex?: number;
  endDayIndex?: number;
  endYear?: number;

  repeats?: "yearly" | "monthly" | "weekly" | "daily" | "none";
  budgetId: string;
  type: BudgetItemType;
  oneOffs?: BudgetItemOneOff[];
};

export type BudgetRow = {
  collapsed?: boolean;
  collapsable?: boolean;
  items: BudgetItem[];
  id: string;
  type?: BudgetRowType;
};

export type Budget = {
  id: string;
  name: string;
  createdOn: Timestamp;
  items?: BudgetItem[];
  linkedBudgetIds?: string[];
  linkedFromBudgetIds?: string[];
  year: number;
  description?: string;
  currencyShortCode?: string;
};

type BudgetState = { [key: string]: Budget };

const initialState: BudgetState = {};

export const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewBudget.fulfilled, (state, action: PayloadAction<Budget>) => {
      state[action.payload.id] = action.payload;
    });
    builder.addCase(updateBudget.fulfilled, (state, action) => {
      state[action.payload.id] = { ...state[action.payload.id], ...action.payload };
    });
    builder.addCase(getAllBudgets.fulfilled, (state, action: PayloadAction<Budget[]>) => {
      return action.payload.reduce((acc: BudgetState, budget) => {
        acc[budget.id] = budget;
        return acc;
      }, {});
    });
    builder.addCase(linkBudgets.fulfilled, (state, action) => {
      const { budgetId, linkedBudgetId } = action.payload;
      state[budgetId].linkedBudgetIds = [
        ...(state[budgetId].linkedBudgetIds ?? []),
        linkedBudgetId,
      ];
      state[linkedBudgetId].linkedFromBudgetIds = [
        ...(state[linkedBudgetId].linkedFromBudgetIds ?? []),
        budgetId,
      ];
    });

    builder.addCase(removeLinkedBudget.fulfilled, (state, action) => {
      const { budgetId, linkedBudgetId } = action.payload;
      state[budgetId].linkedBudgetIds = (state[budgetId].linkedBudgetIds ?? []).filter(
        (f) => f !== linkedBudgetId
      );
      state[linkedBudgetId].linkedFromBudgetIds = (
        state[linkedBudgetId].linkedFromBudgetIds ?? []
      ).filter((f) => f !== budgetId);
    });

    builder.addCase(deleteBudget.fulfilled, (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    });

    builder.addCase(getAllBudgetItemsForId.fulfilled, (state, action) => {
      const { budgetId, items } = action.payload;
      state[budgetId].items = items;
    });

    builder.addCase(addBudgetItem.fulfilled, (state, action) => {
      const { budgetId, item } = action.payload;
      state[budgetId].items = [...(state[budgetId].items ?? []), item];
    });

    builder.addCase(updateBudgetItem.fulfilled, (state, action) => {
      const { budgetId, item } = action.payload;
      state[budgetId].items = (state[budgetId].items ?? []).map((i) =>
        i.id === item.id ? { ...i, ...item } : i
      );
    });

    builder.addCase(removeBudgetItem.fulfilled, (state, action) => {
      const { budgetId, itemId } = action.payload;
      state[budgetId].items = (state[budgetId].items ?? []).filter((f) => f.id !== itemId);
    });
    builder.addCase(getAllBudgetItemOneOffsForId.fulfilled, (state, action) => {
      const { budgetId, itemId, oneOffs } = action.payload;

      if (!state[budgetId].items) state[budgetId].items = [];

      const indexOfItem = (state[budgetId].items ?? []).findIndex((i) => i.id === itemId);

      state[budgetId].items[indexOfItem].oneOffs = oneOffs as BudgetItemOneOff[];
    });
    builder.addCase(addBudgetItemOneOffs.fulfilled, (state, action) => {
      const { budgetId, itemId, oneOffs } = action.payload;

      if (!state[budgetId].items) state[budgetId].items = [];

      const indexOfItem = (state[budgetId].items ?? []).findIndex((i) => i.id === itemId);
      console.log("inedx of item ADD", indexOfItem, oneOffs);
      state[budgetId].items[indexOfItem].oneOffs = oneOffs as BudgetItemOneOff[];
    });

    builder.addCase(updateBudgetItemOneOff.fulfilled, (state, action) => {
      const { budgetId, itemId, oneOff } = action.payload ?? {};
      if (!budgetId || !itemId || !oneOff) return;
      if (!state[budgetId].items) state[budgetId].items = [];

      const indexOfItem = (state[budgetId].items ?? []).findIndex((i) => i.id === itemId);

      console.log("inedx of item UPDATE", indexOfItem, oneOff);
      state[budgetId].items[indexOfItem].oneOffs = (
        state[budgetId].items[indexOfItem].oneOffs ?? []
      ).map((i) => {
        if (i.id === oneOff.id) {
          return oneOff as BudgetItemOneOff;
        }
        return i;
      });
    });

    builder.addCase(removeBudgetItemOneOff.fulfilled, (state, action) => {
      const { budgetId, itemId, oneOffId } = action.payload;

      if (!state[budgetId].items) state[budgetId].items = [];

      const indexOfItem = state[budgetId].items.findIndex((i) => i.id === itemId);

      state[budgetId].items[indexOfItem].oneOffs = state[budgetId].items[
        indexOfItem
      ]?.oneOffs?.filter((f) => f.id !== oneOffId);
    });
  },
});

export const {} = budgetSlice.actions;

export default budgetSlice.reducer;
