import { FC } from "react";
import classnames from "classnames";

import { Budget, BudgetItem, BudgetItemOneOff } from "../../../slices";

import {
  linkBudgets,
  removeBudgetItem,
  removeBudgetItemOneOff,
  removeLinkedBudget,
  updateBudget,
} from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import { selectUserUid } from "../../../selectors";
import { Button } from "../../Button";
import { BudgetInputForm } from "../../BudgetInputForm";
import { monthStringsMap } from "../../../consts";
import { CheckSquare, Disc, Square, Trash2 } from "react-feather";
import { cellDatesToHumanReadable, formatCurrency } from "../../../utils";
import { selectBudgetsArray } from "../../../selectors/budgetSelectors";
type Props = {
  budget: Budget;
};

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center w-8 h-8 ml-3 text-red-500 transition-all rounded-full hover:text-red-50 dark:text-red-200 hover:bg-red-500 dark:hover:bg-red-700"
    >
      <Trash2 size={18} />
    </button>
  );
};

export const BudgetItemsSettingsPanel: FC<Props> = ({ budget }) => {
  const dispatch = useAppDispatch();

  const uid = useAppSelector(selectUserUid);
  const budgets = useAppSelector(selectBudgetsArray);

  if (!uid) return null;

  const handleRemoveBudgetItem = async (item: BudgetItem) => {
    if (!item.id) return;
    await dispatch(removeBudgetItem({ uid, budgetId: budget.id, itemId: item.id }));
  };

  const handleRemoveBudgetItemOneOff = async (item: BudgetItem, oneOffId: string) => {
    if (!item.id) return;
    await dispatch(removeBudgetItemOneOff({ uid, budgetId: budget.id, itemId: item.id, oneOffId }));
  };

  const handleAddLinkedBudget = async (linkedBudgetId: string) => {
    await dispatch(linkBudgets({ uid, budgetId: budget.id, linkedBudgetId }));
  };

  const handleRemoveLinkedBudget = async (linkedBudgetId: string) => {
    await dispatch(removeLinkedBudget({ uid, budgetId: budget.id, linkedBudgetId }));
  };

  const items = (budget.items ?? []).map((item) => {
    const hasOneOffs = (item.oneOffs?.length ?? 0) > 0;
    const wrapperClassnames = classnames(
      "flex flex-1 flex-col rounded-lg py-2 transition-colors hover:bg-gray-200 dark:hover:bg-slate-700",
      {
        // "text-red-700 dark:hover:bg-red-900 hover:bg-red-50 dark:text-red-200":
        //   item.type === "expense",
        // "text-green-700 dark:hover:bg-green-900 hover:bg-green-50 dark:text-green-200":
        //   item.type === "income",
        "pb-3": hasOneOffs,
      }
    );
    return (
      <div className="flex flex-row items-center flex-1 w-full" key={item.id}>
        <div key={item.label} className={wrapperClassnames}>
          <div className="flex flex-row items-center justify-between px-3">
            <div className=" text-slate-500 dark:text-slate-300">
              <p className="text-xs">{item.type}</p>
              <p>{item.label}</p>
              <p className="text-xs">
                {cellDatesToHumanReadable(item.year, item.monthIndex, item.dayIndex)}
                {item.endYear && item.endMonthIndex && item.endDayIndex
                  ? ` to ${cellDatesToHumanReadable(
                      item.endYear,
                      item.endMonthIndex,
                      item.endDayIndex
                    )}`
                  : null}
              </p>
            </div>
            <div className="flex flex-row items-center ">
              {/* <p className="font-mono ">{formatCurrency(item.value, budget.currencyShortCode ?? "USD")}</p> */}

              <Button
                additionalClassnames="!py-1 !px-2 ml-2 text-sm"
                label="Remove"
                onClick={() => handleRemoveBudgetItem(item)}
                type="warning"
              />
            </div>
          </div>

          {hasOneOffs ? (
            <p className="p-1 px-6 mx-2 mt-3 text-sm rounded-t-lg opacity-75 text-slate-700 dark:text-slate-50 bg-slate-300 dark:bg-slate-600 text-end">
              {item.label} One Offs
            </p>
          ) : null}

          <div className="mx-2 rounded-b-lg bg-slate-100 dark:bg-slate-800">
            {item.oneOffs?.map((oneOff, i) => {
              const oneOffWrapperClassnames = classnames(
                "flex flex-row items-center justify-between flex-1 p-1 py-2 mb-1 relative last-of-type:mb-0",
                {}
              );
              return (
                <div className="flex flex-row items-center" key={i}>
                  <div key={i} className={oneOffWrapperClassnames}>
                    <div className="flex flex-row items-center">
                      <Disc className="mx-2" size={16} />
                      <p className="text-sm">
                        {cellDatesToHumanReadable(
                          oneOff.year,
                          oneOff?.monthIndex ?? 1,
                          oneOff?.dayIndex ?? 1
                        )}
                      </p>
                    </div>
                    <div className="flex flex-row items-center">
                      <p className="font-mono text-sm ">
                        {formatCurrency(oneOff.value, budget.currencyShortCode ?? "USD")}
                      </p>
                      <Button
                        additionalClassnames="!py-1 !px-2 ml-2 text-xs"
                        label="Remove"
                        onClick={() => handleRemoveBudgetItemOneOff(item, oneOff.id)}
                        type="warning"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <Button additionalClassnames="py-3 ml-2" type="warning" label="Delete" onClick={() => handleRemoveBudgetItem(item)} /> */}
      </div>
    );
  });
  return (
    <div className="flex flex-col w-full py-1">
      <p className="mt-6 font-bold">Budget Items</p>
      <p className="mb-6 text-sm">Items added to this budget.</p>
      {items}
      <div>
        <p className="mt-6 font-bold">Linked Budgets</p>
        <p className="mb-6 text-sm">Link other budgets to this one</p>
        {budgets.map((b) => {
          if (b.id === budget.id) return null;
          const linksBack = b.linkedBudgetIds?.includes(budget.id);
          const wrapperClassnames = classnames(
            "flex flex-row items-center justify-between px-3 py-1 transition-colors rounded-lg",
            {
              "cursor-not-allowed": b.id === budget.id,
              "cursor-not-allowed opacity-50": linksBack,
              "text-slate-500 dark:text-slate-300 hover:bg-gray-200 dark:hover:bg-slate-700 cursor-pointer":
                !linksBack,
            }
          );
          return (
            <div
              onClick={() => {
                if (linksBack) return;
                budget.linkedBudgetIds?.includes(b.id)
                  ? handleRemoveLinkedBudget(b.id)
                  : handleAddLinkedBudget(b.id);
              }}
              key={b.id}
              className={wrapperClassnames}
            >
              <p>{b.name}</p>
              <div className="flex items-center justify-center w-8 h-8">
                {budget.linkedBudgetIds?.includes(b.id) ? (
                  <CheckSquare size={18} />
                ) : (
                  <Square size={18} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
