import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { monthStringsMap } from "../consts";

const root = (state: RootState) => state.ui;
export const selectViewResolution = createSelector(root, (state) => state.viewResolution);
export const selectCurrentYear = createSelector(root, (state) => state.currentYear);
export const selectCurrentMonth = createSelector(root, (state) => state.currentMonth);

export const selectCurrentDateString = createSelector(
  [selectViewResolution, selectCurrentYear, selectCurrentMonth],
  (viewResolution, year, month) => {
    if (viewResolution === "year") return `${year}`;
    return `${monthStringsMap[String(month)]} ${year}`;
  }
);

export const selectCollapsedRowIds = createSelector(root, (state) => state.collapsedRowIds);
export const selectCurrentlySelectedCell = createSelector(
  root,
  (state) => state.currentlySelectedCell
);
export const selectCurrentViewType = createSelector(root, (state) => state.currentViewType);
