import { FC, FocusEventHandler, FormEvent, FormEventHandler, useEffect, useState } from "react";
import classnames from "classnames";
import { BudgetItem, BudgetItemOneOff, ViewResolution } from "../../../slices";
import { CELL_CLASSNAMES } from "./cellClassnames";
import { formatCurrency } from "../../../utils";
import { Button } from "../../Button";
import { ArrowRight, Disc, Edit, Edit2, Maximize2, Save, Trash2, XCircle } from "react-feather";
import { type } from "os";
import { monthStringsMap, TOOLTIP_IDS } from "../../../consts";
import { useAppSelector } from "../../../hooks";
import { selectViewResolution } from "../../../selectors/uiSelectors";

type Props = {
  cell: BudgetItem;
  currencyShortCode: string;
  isEditing?: boolean;
  onCellClick: () => void;
  onAddOneOffs: (oneOff: BudgetItemOneOff) => void;
  onRemoveOneOffs: (oneOff: BudgetItemOneOff) => void;

  isSubGrid?: boolean;
};

export const DataCellRenderer: FC<Props> = ({
  cell,
  currencyShortCode,
  isEditing,
  onCellClick,
  onAddOneOffs,
  onRemoveOneOffs,
  isSubGrid,
}) => {
  const [localValue, setLocalValue] = useState(cell.value ?? 0);
  const viewResolution = useAppSelector(selectViewResolution);

  useEffect(() => {
    setLocalValue(cell.value ?? 0);
  }, [cell.value]);

  const cellValue = cell?.value ?? 0;

  const wrapperClassnames = classnames(
    CELL_CLASSNAMES.base,
    CELL_CLASSNAMES.dataBase,
    "justify-between group",
    {
      [CELL_CLASSNAMES.empty]: (!isEditing && cellValue === 0) || (isEditing && localValue === 0),
      [CELL_CLASSNAMES.positive]: (!isEditing && cellValue > 0) || (isEditing && localValue > 0),
      [CELL_CLASSNAMES.negative]: (!isEditing && cellValue < 0) || (isEditing && localValue < 0),
      [CELL_CLASSNAMES.emptyEditable]: cellValue === 0 && !isEditing,
      [CELL_CLASSNAMES.positiveEditable]: cellValue > 0 && !isEditing,
      [CELL_CLASSNAMES.negativeEditable]: cellValue < 0 && !isEditing,
      [CELL_CLASSNAMES.editing]: isEditing,
    }
  );

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
  };
  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    // onCellClick();
  };
  const handleCancelClick = () => {
    setLocalValue(cell.value ?? 0);
    onCellClick();
  };

  const handleSaveOneOff = () => {
    const oneOff: BudgetItemOneOff = {
      value: localValue,
      monthIndex: cell.monthIndex,
      dayIndex: cell.dayIndex,
      year: cell.year,
      id: `${cell.budgetId}-${cell.year}-${cell.monthIndex}-${cell.dayIndex}`,
    };
    onAddOneOffs(oneOff);
  };

  const handleSaveOneOffClick = () => {
    handleSaveOneOff();
  };
  const handleEnterPress = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSaveOneOff();
  };

  const handleRemoveOneOff = () => {
    const oneOffToRemove = cell.oneOffs?.find(
      (o) => o.id === `${cell.budgetId}-${cell.year}-${cell.monthIndex}-${cell.dayIndex}`
    );

    if (oneOffToRemove) {
      onRemoveOneOffs(oneOffToRemove);
    }
  };

  const handleLocalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(Number(e.target.value));
  };

  const formattedValue = formatCurrency(cell.value ?? 0, currencyShortCode);

  // if (isEditing) {
  //   return (
  //     <div className="relative flex flex-row bg-white border border-gray-300 rounded-xl dark:border-slate-600 dark:bg-slate-700">

  //     </div>
  //   );
  // }
  const hasMultipleValues =
    (!isEditing && cell.repeats === "daily" && viewResolution === "year" && !isSubGrid) ||
    cell.type === "oneOffMultiple";
  const hasOneOffs = !isEditing && (cell.type === "oneOff" || cell.type === "oneOffMultiple");

  if (isEditing) {
    return (
      <div className="relative flex shadow-2xl">
        <div className="absolute z-40 rounded-xl overflow-hidden w-[200%] shadow-2xl bg-transparent bg-yellow-50 dark:bg-yellow-600  border-2 border-yellow-300 -inset-y-full grid grid-cols-2 ">
          <div className="flex flex-col items-center justify-center flex-1 h-full max-w-full pl-3 text-end">
            <div className="flex items-center justify-center flex-1">
              <p className="font-bold text-yellow-600 text-md dark:text-yellow-50">
                {monthStringsMap[cell.monthIndex]} {cell.dayIndex}
              </p>
            </div>
            <form className="max-w-full" onSubmit={handleEnterPress}>
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoFocus
                type="number"
                className={wrapperClassnames}
                value={localValue}
                onChange={handleLocalValueChange}
              />
            </form>
            <div className="flex-1" />
          </div>

          <div className="flex flex-col justify-between order-last p-3 bg-yellow-50 dark:bg-yellow-600">
            <Button
              additionalClassnames="flex !justify-center mb-2"
              onClick={handleSaveOneOffClick}
              type="primary"
              label={cell.type === "oneOff" ? "Update" : "Save"}
            />

            <Button
              additionalClassnames="flex !justify-center mb-2"
              onClick={() => null}
              label="Repeat"
              tooltipId={TOOLTIP_IDS.INFO_ONE_OFF_SAVE_REPEATING.id}
              type="primary"
            />
            {cell.type === "oneOff" ? (
              <Button
                additionalClassnames="flex !justify-center"
                onClick={handleRemoveOneOff}
                type="warning"
                label="Remove"
              />
            ) : (
              <Button
                additionalClassnames="flex  !justify-center"
                onClick={handleCancelClick}
                type="secondary"
                label="Cancel"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={onCellClick} className={wrapperClassnames}>
      <div className="flex flex-row items-center h-full">
        {hasOneOffs && (
          <>
            {!hasMultipleValues && <Edit2 className="hidden mr-2 group-hover:block" size={20} />}
            <div className="w-2 h-4 mr-1 bg-blue-600 rounded-full group-hover:hidden dark:bg-blue-500" />
          </>
        )}
        {hasMultipleValues && (
          <>
            <Maximize2 className="hidden mr-2 group-hover:block" size={20} />
            <div className="w-2 h-4 bg-pink-500 rounded-full group-hover:hidden dark:bg-pink-400" />
          </>
        )}
      </div>

      {/* {!isEditing && (cell.type === "oneOff" || cell.type === "oneOffMultiple") ? (
        <div className="mr-2">
          <Disc size={16} />
        </div>
      ) : (
        ""
      )}
       */}
      {/* {(cell.repeats === "daily" && viewResolution === "year") || cell.type === "oneOffMultiple" ? (
        <div className="mr-2">
          <Maximize2 size={16} />
        </div>
      ) : (
        ""
      )} */}

      {!isEditing && formattedValue}
    </div>
  );
};
