import { getDaysInMonth } from "date-fns";
import { monthStringsMap, yearHeaderStrings } from "../consts";
import {
  Budget,
  BudgetItem,
  BudgetItemOneOff,
  BudgetRow,
  ViewResolution,
} from "../slices";

const getHeaderStrings = (
  viewResolution: ViewResolution,
  currentMonth: number,
  currentYear: number
) => {
  if (viewResolution === "year") {
    return yearHeaderStrings;
  } else {
    const currentDate = new Date(currentYear, currentMonth - MONTH_OFFSET);
    const daysInCurrentMonth = getDaysInMonth(currentDate);
    return [
      ...Array.from({ length: daysInCurrentMonth }, (_, i) => String(i + 1)),
      "Total",
    ];
  }
};

export const createHeaderRow = (
  budget: Budget,
  viewResolution: ViewResolution,
  currentMonth: number,
  currentYear: number
): BudgetRow => {
  const headerStrings = getHeaderStrings(
    viewResolution,
    currentMonth,
    currentYear
  );
  const baseCell = { type: "header", budgetId: budget.id } as BudgetItem;

  const rowHeader =
    viewResolution === "year"
      ? String(currentYear)
      : monthStringsMap[currentMonth];

  return {
    id: budget.id,
    type: "header",
    items: [
      { ...baseCell, type: "rowHeader", label: rowHeader },
      ...headerStrings.map(
        (month, i) =>
          ({
            ...baseCell,
            label: month,
            year: currentYear,
            monthIndex: viewResolution === "year" ? i + 1 : currentMonth,
            dayIndex: viewResolution === "year" ? 1 : i + 1,
          } as BudgetItem)
      ),
    ],
  };
};

const generateBaseRow = (numCols: number, budgetId: string): BudgetItem[] => {
  return Array(numCols)
    .fill(0)
    .map((_) => ({ type: "empty", label: "", budgetId } as BudgetItem));
};

// Translate display month index (1...12) to JS date index (0...11)
const MONTH_OFFSET = 1;
// 14 because 1 for header, 12 for months, 1 for total
const YEAR_COLS = 14;

export const createDefaultRows = (
  budget: Budget,
  viewResolution: ViewResolution,
  currentYear: number,
  currentMonth: number
): BudgetRow[] => {
  const currentDate = new Date(currentYear, currentMonth - MONTH_OFFSET);
  const daysInCurrentMonth = getDaysInMonth(currentDate);
  const numCols =
    viewResolution === "year" ? YEAR_COLS : daysInCurrentMonth + 2;

  return (budget?.items ?? []).map((item) => {
    /**
     * Different Scenarios:
     *
     * Year View:
     *  - Non-repeating item
     *
     *  - Monthly repeating item without end date
     *  - Monthly repeating item with end date
     *
     *  - Daily repeating item without end date
     *  - Daily repeating item with end date
     *
     * Month View:
     *  - Non-repeating item
     *
     *  - Monthly repeating item without end date
     *  - Monthly repeating item with end date
     *
     *  - Daily repeating item without end date
     *  - Daily repeating item with end date
     */

    const oneOffsByIndex = (item?.oneOffs ?? []).reduce((acc, curr) => {
      const index = viewResolution === "year" ? curr.monthIndex : curr.dayIndex;
      if (!acc[index ?? 1]) {
        acc[index ?? 1] = [];
      }

      if (curr.year !== currentYear) return acc;

      if (viewResolution === "month" && curr.monthIndex !== currentMonth) {
        return acc;
      }

      acc[index ?? 1].push(curr);

      return acc;
    }, {} as Record<number, BudgetItemOneOff[]>);

    const row = Array(numCols)
      .fill(0)
      .map((_, index) => {
        const base = {
          type: "empty",
          label: "",
          budgetId: budget.id,
          year: currentYear,
          id: item.id,
        } as BudgetItem;

        if (index !== 0 && index !== numCols - 1) {
          if (viewResolution === "month") {
            base.dayIndex = index;
            base.monthIndex = currentMonth;
          }
          if (viewResolution === "year") {
            base.dayIndex = 1;
            base.monthIndex = index;
          }
        }
        return base;
      });

    row[0].type = "rowHeader";
    row[0].label = item.label;

    const itemRepeats = item?.repeats && item.repeats !== "none";

    const itemValue = item.type !== "hidden" ? Number(item.value) ?? 0 : 0;

    if (!itemRepeats) {
      if (viewResolution === "year") {
        const isInRange = item.year === currentYear;

        if (isInRange) {
          row[item.monthIndex] = item;
        }
      }

      if (viewResolution === "month") {
        const isInRange =
          item.year === currentYear && item.monthIndex === currentMonth;

        if (isInRange) {
          row[item.dayIndex] = item;
        }
      }
    }

    if (item.repeats === "monthly") {
      if (viewResolution === "month") {
        const isInRange =
          item.endYear &&
          item.endMonthIndex &&
          item.endDayIndex &&
          item.endYear === currentYear
            ? currentYear >= item.year &&
              currentYear <= item.endYear &&
              currentMonth >= item.monthIndex &&
              currentMonth <= item.endMonthIndex
            : currentYear >= item.year && currentMonth >= item.monthIndex;

        if (isInRange) {
          const startIndex = item.dayIndex;
          const endIndex = daysInCurrentMonth;

          row[startIndex] = {
            ...item,
            dayIndex: startIndex,
            monthIndex: currentMonth,
          };
        }
      }

      if (viewResolution === "year") {
        const isInRange = item.endYear
          ? currentYear >= item.year && currentYear <= item.endYear
          : currentYear >= item.year;

        if (isInRange) {
          let startIndex = 1;
          let endIndex = 12;

          if (currentYear === item.year) {
            startIndex = item.monthIndex;
          }

          if (item.endMonthIndex && item.endYear === currentYear) {
            endIndex = item.endMonthIndex;
          }

          for (let i = startIndex; i <= endIndex; i++) {
            row[i] = { ...item, monthIndex: i, dayIndex: item.dayIndex };
          }
        }
      }
    }

    if (item.repeats === "daily") {
      if (viewResolution === "month") {
        const isInRange =
          item.endYear && item.endMonthIndex && item.endDayIndex
            ? currentYear >= item.year &&
              currentYear <= item.endYear &&
              currentMonth >= item.monthIndex &&
              currentMonth <= item.endMonthIndex
            : currentYear >= item.year && currentMonth >= item.monthIndex;

        if (isInRange) {
          let startIndex = 1;
          let endIndex = daysInCurrentMonth;

          if (currentMonth === item.monthIndex) {
            startIndex = item.dayIndex;
          }

          if (item.endDayIndex) {
            if (
              currentMonth === item.endMonthIndex &&
              currentYear === item.endYear
            ) {
              endIndex = item.endDayIndex;
            }
          }

          for (let i = startIndex; i <= endIndex; i++) {
            row[i] = { ...item, dayIndex: i, monthIndex: currentMonth };
          }
        }
      }

      if (viewResolution === "year") {
        const isInRange = item.endYear
          ? currentYear >= item.year && currentYear <= item.endYear
          : currentYear >= item.year;

        if (isInRange) {
          const itemValue =
            item.type !== "hidden" ? Number(item.value) ?? 0 : 0;

          let monthStartIndex = 1;
          let monthEndIndex = 12;

          if (currentYear === item.year) {
            monthStartIndex = item.monthIndex;
          }

          if (item.endMonthIndex) {
            if (item.endYear === currentYear) {
              monthEndIndex = item.endMonthIndex;
            }
          }

          for (let i = monthStartIndex; i <= monthEndIndex; i++) {
            let monthTotal = 0;
            const date = new Date(currentYear, i - MONTH_OFFSET);
            const daysInMonth = getDaysInMonth(date);

            let dayStartIndex = 1;
            let dayEndIndex = daysInMonth;

            if (item.monthIndex === i) {
              dayStartIndex = item.dayIndex;
            }

            if (item.endDayIndex && item.endDayIndex > i) {
              dayEndIndex = item.endDayIndex;
            }

            for (let j = dayStartIndex; j <= dayEndIndex; j++) {
              monthTotal += itemValue;
            }

            row[i] = {
              ...item,
              monthIndex: i,
              dayIndex: item.dayIndex,
              value: monthTotal,
            };
          }
        }
      }
    }

    row.forEach((cell, i) => {
      const oneOffs = (oneOffsByIndex[i] ?? []).filter((f) => {
        if (viewResolution === "year") {
          return f.year === currentYear && f.monthIndex === i;
        }
        if (viewResolution === "month") {
          return (
            f.year === currentYear &&
            f.monthIndex === currentMonth &&
            f.dayIndex === i
          );
        }
        return false;
      });

      const hasOneOffs = oneOffs.length > 0;
      const hasMultipleOneOffs = oneOffs.length > 1;
      const oneOffTotal = hasMultipleOneOffs
        ? oneOffs.reduce((acc, curr) => acc + curr.value, 0)
        : oneOffs[0]?.value ?? 0;

      if (hasOneOffs) {
        // debugger;
        const newValue = cell.value
          ? cell.value - itemValue + oneOffTotal
          : oneOffTotal;
        row[i] = {
          ...row[i],
          value: newValue,
          type: hasMultipleOneOffs ? "oneOffMultiple" : "oneOff",
        };
        // row[i].value = newValue;
        // row[i].type = hasMultipleOneOffs ? "oneOffMultiple" : "oneOff";
      }
    });

    const rowTotal = row.slice(1, numCols - 1).reduce((acc, curr) => {
      return acc + (curr.value ?? 0);
    }, 0);
    row[numCols - 1].type = "rowFooter";
    row[numCols - 1].value = rowTotal;

    return { id: budget.id, items: row };
  });
};

export const createFooterRow = (
  budget: Budget,
  rows: BudgetRow[]
): BudgetRow => {
  // Assume all rows are the same length. They should be.

  const numCols = (rows[0]?.items ?? []).length;
  if (numCols === 0) return { id: budget.id, items: [] };
  const baseCell = { type: "footer", budgetId: budget.id } as BudgetItem;

  // Create a row with the same number of columns as the other rows but with cells that contain the sum of the values in the column
  const footerRow = Array(numCols)
    .fill(0)
    .map((_) => ({ ...baseCell, label: "", value: 0 })) as BudgetItem[];

  footerRow[0].label = "Total";

  for (let i = 1; i < numCols; i++) {
    const total = rows.reduce((acc, curr) => {
      const cell = curr.items[i] as BudgetItem;
      if (!cell || cell?.type === "hidden" || cell?.type === "info") return acc;
      return (acc ?? 0) + (Number(cell.value) || 0);
    }, 0);
    footerRow[i].value = total;
  }

  return { ...budget, items: footerRow };
};

export const createRowHeaders = (
  labels: (string | undefined)[],
  numCols: number,
  budgetId: string
): BudgetRow[] => {
  return labels.map((label) => {
    const row = {
      id: budgetId,
      items: Array(numCols).fill({ type: "empty", label: "", budgetId }),
    }; // numCols because 1 label + 12 months
    row.items[0] = { label, type: "rowHeader", budgetId }; // Set the first cell as the label
    return row;
  });
};

export const createTotalRow = (
  matrix: BudgetRow[],
  numCols: number,
  budgetId: string
): BudgetRow => {
  return Array(numCols)
    .fill(0)
    .reduce(
      (acc, curr, i) => {
        if (i === 0) {
          acc.items.push({ label: "Total", type: "rowHeader", budgetId });
        } else {
          const total = matrix.slice(1).reduce((acc, curr) => {
            const cell = curr.items[i] as BudgetItem;
            if (!cell || cell?.type === "hidden" || cell?.type === "info")
              return acc;
            return (acc ?? 0) + (Number(cell.value) || 0);
          }, 0);
          acc.items.push({
            value: total,
            type: "footer",
            budgetId,
          });
        }
        return acc;
      },
      { items: [] }
    );
};
