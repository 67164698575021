export const TOOLTIP_IDS = {
  INFO_ONE_OFF_CELL: { id: "info-one-off-cell", content: "This is a one off" },
  INFO_ONE_OFF_SAVE_ONCE: { id: "save-once-tooltip", content: "Save" },
  INFO_ONE_OFF_SAVE_REPEATING: {
    id: "save-repeating-tooltip",
    content: "Save and repeat until end",
  },
  INFO_ONE_OFF_REMOVE: { id: "remove-one-off-tooltip", content: "Remove one off" },
  INFO_ONE_OFF_CANCEL: { id: "cancel-one-off-tooltip", content: "Cancel" },
};
