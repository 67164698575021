import { FC } from "react";
import classnames from "classnames";
import { Budget, BudgetRow } from "../../../slices";
import { ROW_CLASSNAMES } from "./rowClassnames";
import { FooterCellRenderer } from "../CellRenderers";

type Props = {
  row: BudgetRow;
  budget: Budget;
};

export const FooterRowRenderer: FC<Props> = ({ row, budget }) => {
  const wrapperClassnames = classnames(ROW_CLASSNAMES.base, ROW_CLASSNAMES.footer);
  return (
    <div className={wrapperClassnames}>
      {(row?.items ?? []).map((cell, index) => (
        <FooterCellRenderer
          currencyShortCode={budget.currencyShortCode ?? "USD"}
          key={index}
          cell={cell}
          sticky={
            index === 0 ? "left" : index === (row?.items ?? []).length - 1 ? "right" : undefined
          }
          index={index}
        />
      ))}
    </div>
  );
};
