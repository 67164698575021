export const CELL_CLASSNAMES = {
  base: "p-4 transition-colors ",
  header:
    "truncate border-r border-gray-300 dark:border-slate-600 text-end text-gray-500 dark:text-slate-300 bg-slate-50 dark:bg-slate-800",
  stickyLeft: "sticky left-0 z-10",
  stickyRight: "sticky right-0 z-10",
  dataBase: "font-mono max-w-full flex flex-row justify-end items-center text-end",
  empty: "bg-white dark:bg-slate-700 text-gray-300 dark:text-slate-600",
  emptyEditable: "cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800",
  positive: "text-green-700 bg-green-50 dark:bg-green-950 dark:text-green-500 ",
  positiveEditable: "cursor-pointer hover:bg-green-100 hover:bg-green-100 dark:hover:bg-green-900",
  negative: "text-red-700 bg-red-50  dark:bg-red-950   dark:text-red-300",
  negativeEditable: "cursor-pointer hover:bg-red-100 dark:hover:bg-red-900",
  editing:
    "font-bold text-yellow-600 bg-yellow-50 z-20 relative rounded-lg text-2xl border-2 border-yellow-300 ",
  footer: "font-bold",
  info: "sticky left-0 z-10 flex py-2 ",
  currentDate: "dark:!text-red-400 text-red-400 font-bold",
};
