import { Menu, MenuButton, MenuItem, MenuItems, MenuSection } from "@headlessui/react";
import { FC, ReactElement } from "react";
import { ChevronDown, Menu as MenuIcon } from "react-feather";
import classnames from "classnames";

export type DropdownItem = {
  dividerTop?: boolean;
  dividerBottom?: boolean;
  icon?: ReactElement;
  label: string;
  onClick?: () => void;
};

interface Props {
  triggerLabelString?: ReactElement | string;
  triggerLabelIcon?: ReactElement;
  trigger?: ReactElement;
  additionalClassnames?: string;
  sections: {
    heading?: string;
    items: DropdownItem[];
  }[];
}

export const Dropdown: FC<Props> = ({
  additionalClassnames,
  trigger,
  triggerLabelIcon,
  triggerLabelString,
  sections,
}) => {
  return (
    <div className={additionalClassnames}>
      <Menu>
        <MenuButton>
          {({ open }) => {
            const wrapperClassnames = classnames(
              // "flex items-center justify-between w-full p-3 text-gray-600 hover:bg-gray-100 dark:hover:bg-slate-800 dark:text-slate-300 transition-colors",
              // { "bg-gray-100 dark:bg-slate-800 ": open }
              "py-2 px-4 pr-3 hover:shadow-lg transition-all  rounded-xl flex flex-row items-center justify-between text-gray-900 border dark:text-slate-200 hover:bg-gray-200 dark:hover:bg-slate-700 border-gray-300 dark:border-slate-600",

              {
                "bg-gray-100 dark:bg-slate-800": !open,
                "bg-gray-200 dark:bg-slate-700 shadow-lg": open,
              }
            );
            return (
              <div className={wrapperClassnames}>
                {triggerLabelIcon ? triggerLabelIcon : null}
                {triggerLabelString ? triggerLabelString : null}
                <ChevronDown className="ml-2 opacity-50" />
              </div>
            );
          }}
          {/* {label ? (
          label
        ) : (
          <div className="flex flex-row items-center px-4 py-2 text-green-900 transition-colors bg-teal-300 rounded hover:bg-teal-400 hover:shadow-lg dark:bg-teal-800 dark:text-green-200 dark:hover:bg-teal-700">
            <MenuIcon className="mr-2" />
            Settings
          </div>
        )} */}
        </MenuButton>

        <MenuItems
          transition
          anchor={{ to: "bottom end", gap: "0.5rem" }}
          className="z-40 origin-top-right rounded-xl border bg-gray-50 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-200 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 shadow-xl w-72"
        >
          {sections.map((section, index) => (
            <MenuSection key={index}>
              {section.heading && (
                <div className="px-3 py-2 text-sm font-semibold text-gray-500 bg-gray-200 dark:text-slate-400 dark:bg-slate-600">
                  {section.heading}
                </div>
              )}
              {section.items.map((item, index) => (
                <MenuItem key={index}>
                  {({ close }) => (
                    <>
                      {item.dividerTop && <div className="border-b dark:border-slate-500" />}
                      <button
                        onClick={() => {
                          item.onClick?.();
                          close();
                        }}
                        className="flex items-center justify-between w-full p-3 text-gray-600 hover:bg-gray-100 dark:hover:bg-slate-800 dark:text-slate-300 group"
                      >
                        <div className="mr-2 opacity-75">{item.icon}</div>
                        <span className="truncate">{item.label}</span>
                      </button>
                      {item.dividerBottom && <div className="border-t dark:border-slate-500" />}
                    </>
                  )}
                </MenuItem>
              ))}
            </MenuSection>
            // <MenuItem key={index}>
            //   {({ close }) => (
            //     <>
            //       {item.dividerTop && <div className="border-b dark:border-slate-500" />}
            //       <button
            //         onClick={() => {
            //           item.onClick?.();
            //           close();
            //         }}
            //         className="flex items-center justify-between w-full p-4 group hover:text-green-900 dark:hover:text-green-200 hover:bg-teal-400 dark:hover:bg-teal-700"
            //       >
            //         <div className="mr-2 opacity-50">{item.icon}</div>
            //         <span className="truncate">{item.label}</span>
            //       </button>
            //       {item.dividerBottom && <div className="border-t dark:border-slate-500" />}
            //     </>
            //   )}
            // </MenuItem>
          ))}
        </MenuItems>
      </Menu>
    </div>
  );
};
